import { birthdayAPI, scanBandAPI } from '../../api/api';
import {
    getBirthday,
    startBirthday,
    stopBirthday,
    stopBirthdaySaveData,
} from '../actions/sse.action';
import { getUserFromBand } from '../actions/scanBand.action.js';

export function getBirthdayAPI() {
    return async function (dispatch) {
        const { data } = await birthdayAPI.getBirthday();
        dispatch(getBirthday(data));
    };
}

export function startBirthdayAPI(body) {
    return async function (dispatch) {
        const { data } = await birthdayAPI.startBirthday(body);
        dispatch(startBirthday(data));
    };
}

export function stopBirthdayAPI() {
    return async function (dispatch) {
        await birthdayAPI.stopBirthday();
        dispatch(stopBirthday());
    };
}

export function resetBirthdayAPI() {
    return async function (dispatch) {
        await birthdayAPI.stopBirthday();
        birthdayAPI.resetBirthday();
        dispatch(stopBirthday());
    };
}

export function stopBirthdayAndSaveDataAPI() {
    return async function (dispatch) {
        await birthdayAPI.stopBirthday();
        dispatch(stopBirthdaySaveData());
    };
}

export function getUserFromBandAPI(bandID) {
    return async function (dispatch) {
        const { data } = await scanBandAPI.getAllUsers();
        const userData = data.find(user => user.id.toString() === bandID.toString());
        dispatch(getUserFromBand(userData ?? {}));
    };
}
