const addLines = (howMany) => {
    const sum = [];
    for (let i = 0; i < howMany; i++) {
        sum.push([]);
    }
    return sum;
};

const twentyLines = addLines(15);

export default twentyLines;
