export const OPEN_SSE_SCAN_BAND = 'OPEN_SSE_SCAN_BAND';
// export const GET_BAND_UUID = 'GET_BAND_UUID';
export const GET_BAND_ID = 'GET_BAND_ID';
export const SET_BAND_SCANNED = 'SET_BAND_SCANNED';
export const GET_USER_FROM_BAND = 'GET_USER_FROM_BAND';

export const openSSEBandScan = () => {
    return { type: OPEN_SSE_SCAN_BAND };
};

// Depracated
// export const getBandUUID = (body) => {
//     return { type: GET_BAND_UUID, body };
// };

export const getBandID = (body) => {
    return { type: GET_BAND_ID, body };
};

export const setBandScanned = (body) => {
    return { type: SET_BAND_SCANNED, body };
};

export const getUserFromBand = (body) => {
    return { type: GET_USER_FROM_BAND, body };
};
