import { call, put, takeEvery } from 'redux-saga/effects';
import { rankingAPI } from '../../api/api';
import { addAlert } from '../actions/stickyAlert.action';
import {
    GET_BEST_OF_THE_DAY,
    setBestOfTheDay,
} from '../actions/ranking.action';

export function* rankingSaga() {
    yield takeEvery(GET_BEST_OF_THE_DAY, function* () {
        try {
            const response = yield call(rankingAPI.getBestOfTheDay);
            // yield put(setBestOfTheDay(response.data));
            console.log(response.data);
        } catch (error) {
            yield put(addAlert(error.message));
        }
    });
}
