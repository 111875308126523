export function dynamicSort(property) {
    const sortOrder = -1;
    return function (a, b) {
        if (a[property] < b[property]) {
            return -1 * sortOrder;
        } else if (a[property] > b[property]) {
            return 1 * sortOrder;
        } else {
            return 0 * sortOrder;
        }
    };
}
