import aiko from '../../../assets/default/images/tag-heroez/characters/Aiko.png';
import aput from '../../../assets/default/images/tag-heroez/characters/Aput.png';
import arro from '../../../assets/default/images/tag-heroez/characters/Arro.png';
import avia from '../../../assets/default/images/tag-heroez/characters/Avia.png';
import billy from '../../../assets/default/images/tag-heroez/characters/Billy.png';
import bjorn from '../../../assets/default/images/tag-heroez/characters/Bjorn.png';
import dina from '../../../assets/default/images/tag-heroez/characters/Dina.png';
import hana from '../../../assets/default/images/tag-heroez/characters/Hana.png';
import hawk from '../../../assets/default/images/tag-heroez/characters/Hawk.png';
import kenji from '../../../assets/default/images/tag-heroez/characters/Kenji.png';
import lily from '../../../assets/default/images/tag-heroez/characters/Lily.png';
import mona from '../../../assets/default/images/tag-heroez/characters/Mona.png';
import pharo from '../../../assets/default/images/tag-heroez/characters/Pharo.png';
import regi from '../../../assets/default/images/tag-heroez/characters/Regi.png';
import rex from '../../../assets/default/images/tag-heroez/characters/Rex.png';
import sara from '../../../assets/default/images/tag-heroez/characters/Sara.png';
import seth from '../../../assets/default/images/tag-heroez/characters/Seth.png';
import vesp from '../../../assets/default/images/tag-heroez/characters/Vesp.png';
import viki from '../../../assets/default/images/tag-heroez/characters/Viki.png';
import yuki from '../../../assets/default/images/tag-heroez/characters/Yuki.png';

import aiko_icon from '../../../assets/default/images/tag-heroez/icons/Aiko_Icon.png';
import aput_icon from '../../../assets/default/images/tag-heroez/icons/Aput_Icon.png';
import arro_icon from '../../../assets/default/images/tag-heroez/icons/Arro_Icon.png';
import avia_icon from '../../../assets/default/images/tag-heroez/icons/Avia_Icon.png';
import billy_icon from '../../../assets/default/images/tag-heroez/icons/Billy_Icon.png';
import bjorn_icon from '../../../assets/default/images/tag-heroez/icons/Bjorn_Icon.png';
import dina_icon from '../../../assets/default/images/tag-heroez/icons/Dina_Icon.png';
import hana_icon from '../../../assets/default/images/tag-heroez/icons/Hana_Icon.png';
import hawk_icon from '../../../assets/default/images/tag-heroez/icons/Hawk_Icon.png';
import kenji_icon from '../../../assets/default/images/tag-heroez/icons/Kenji_Icon.png';
import lily_icon from '../../../assets/default/images/tag-heroez/icons/Lily_Icon.png';
import mona_icon from '../../../assets/default/images/tag-heroez/icons/Mona_Icon.png';
import pharo_icon from '../../../assets/default/images/tag-heroez/icons/Pharo_Icon.png';
import regi_icon from '../../../assets/default/images/tag-heroez/icons/Regi_Icon.png';
import rex_icon from '../../../assets/default/images/tag-heroez/icons/Rex_Icon.png';
import sara_icon from '../../../assets/default/images/tag-heroez/icons/Sara_Icon.png';
import seth_icon from '../../../assets/default/images/tag-heroez/icons/Seth_Icon.png';
import vesp_icon from '../../../assets/default/images/tag-heroez/icons/Vesp_Icon.png';
import viki_icon from '../../../assets/default/images/tag-heroez/icons/Viki_Icon.png';
import yuki_icon from '../../../assets/default/images/tag-heroez/icons/Yuki_Icon.png';

import aiko_napis from '../../../assets/default/images/tag-heroez/names/aiko.png';
import aput_napis from '../../../assets/default/images/tag-heroez/names/aput.png';
import arro_napis from '../../../assets/default/images/tag-heroez/names/arro.png';
import avia_napis from '../../../assets/default/images/tag-heroez/names/avia.png';
import billy_napis from '../../../assets/default/images/tag-heroez/names/billy.png';
import bjorn_napis from '../../../assets/default/images/tag-heroez/names/bjorn.png';
import dina_napis from '../../../assets/default/images/tag-heroez/names/dina.png';
import hana_napis from '../../../assets/default/images/tag-heroez/names/hana.png';
import hawk_napis from '../../../assets/default/images/tag-heroez/names/hawk.png';
import kenji_napis from '../../../assets/default/images/tag-heroez/names/kenji.png';
import lily_napis from '../../../assets/default/images/tag-heroez/names/lily.png';
import mona_napis from '../../../assets/default/images/tag-heroez/names/mona.png';
import pharo_napis from '../../../assets/default/images/tag-heroez/names/pharo.png';
import regi_napis from '../../../assets/default/images/tag-heroez/names/regi.png';
import rex_napis from '../../../assets/default/images/tag-heroez/names/rex.png';
import sara_napis from '../../../assets/default/images/tag-heroez/names/sara.png';
import seth_napis from '../../../assets/default/images/tag-heroez/names/seth.png';
import vesp_napis from '../../../assets/default/images/tag-heroez/names/vesp.png';
import viki_napis from '../../../assets/default/images/tag-heroez/names/viki.png';
import yuki_napis from '../../../assets/default/images/tag-heroez/names/yuki.png';

const elements = {
    0: { character: bjorn, icon: bjorn_icon, charName: bjorn_napis },
    1: { character: vesp, icon: vesp_icon, charName: vesp_napis },
    2: { character: yuki, icon: yuki_icon, charName: yuki_napis },
    3: { character: dina, icon: dina_icon, charName: dina_napis },
    4: { character: rex, icon: rex_icon, charName: rex_napis },
    5: { character: kenji, icon: kenji_icon, charName: kenji_napis },
    6: { character: avia, icon: avia_icon, charName: avia_napis },
    7: { character: mona, icon: mona_icon, charName: mona_napis },
    8: { character: arro, icon: arro_icon, charName: arro_napis },
    9: { character: viki, icon: viki_icon, charName: viki_napis },
    10: { character: aiko, icon: aiko_icon, charName: aiko_napis },
    11: { character: aput, icon: aput_icon, charName: aput_napis },
    12: { character: billy, icon: billy_icon, charName: billy_napis },
    13: { character: hana, icon: hana_icon, charName: hana_napis },
    14: { character: hawk, icon: hawk_icon, charName: hawk_napis },
    15: { character: lily, icon: lily_icon, charName: lily_napis },
    16: { character: pharo, icon: pharo_icon, charName: pharo_napis },
    17: { character: regi, icon: regi_icon, charName: regi_napis },
    18: { character: sara, icon: sara_icon, charName: sara_napis },
    19: { character: seth, icon: seth_icon, charName: seth_napis },
    20: { character: bjorn, icon: bjorn_icon, charName: bjorn_napis },
};

// const elements = {
//   1: {character: aiko, icon: aiko_icon, name: aiko_napis},
//   2: {character: aput, icon: aput_icon, name: aput_napis},
//   3: {character: arro, icon: arro_icon, name: arro_napis},
//   4: {character: avia, icon: avia_icon, name: avia_napis},
//   5: {character: dina, icon: dina_icon, name: dina_napis},
//   6: {character: hana, icon: hana_icon, name: hana_napis},
//   7: {character: hawk, icon: hawk_icon, name: hawk_napis},
//   8: {character: kenji, icon: kenji_icon, name: kenji_napis},
//   9: {character: mona, icon: mona_icon, name: mona_napis},
//   10: {character: rex, icon: rex_icon, name: rex_napis},
//   11: {character: sara, icon: sara_icon, name: sara_napis},
//   12: {character: seth, icon: seth_icon, name: seth_napis},
//   13: {character: vesp, icon: vesp_icon, name: vesp_napis},
//   14: {character: viki, icon: viki_icon, name: viki_napis},
//   15: {character: yuki, icon: yuki_icon, name: yuki_napis},
// };

export default elements;
