import React, { useEffect, useState } from 'react';
import classes from './Player.module.css';
import { useSelector } from 'react-redux';
// import lightningIcon from '../../../../assets/default/images/ranking/lightning-icon.png';
import lightningIcon from '../../../../assets/default/images/ranking/ikonka.svg';
import { NUM_OF_PLAYERS_PER_PAGE } from '../../common/constants';

import Icon from './Icon';
// import countOccurences from '../../common/countOccurences';

const Player = ({ item, maxId, maxValue, idx, currentPage }) => {
    const icons = useSelector((state) => state.adminPanel.icons);

    const scoreWidth = maxValue > 999 ? '19%' : '15%';

    const isTopPlayer = item.key === maxId && maxId > 0 && item.value === maxValue;

    // const position =
    //     scores.indexOf(scores.filter((sc) => sc === item.value)[0]) +
    //     countOccurences(scores, item.value) -
    //     1;

    // console.log('Player ', item.key, 'Value: ', (item.value * 100) / max - 5);

    const barWidth = (item.value * 100) / maxValue - 5;
    const playerBarWidth = barWidth < 0 ? 0 : barWidth;

    const moduloIdx = idx % NUM_OF_PLAYERS_PER_PAGE;
    const playerTopIdx =
        idx / NUM_OF_PLAYERS_PER_PAGE >= 1 ? moduloIdx + 1 : moduloIdx;

    const [leftPos, setLeftPos] = useState(isTopPlayer ? '-5%' : '0%');

    // const calcLeftPos = (playerIndex, currentPage) => {
    //   if (playerIndex === 0) return '-5%';
    //
    //   if (playerIndex >= currentPage * 15) {
    //     setTimeout(() => {
    //       setLeftPos("110%");
    //     }, idx * 50)
    //     // return "110%";
    //   } else if (playerIndex < (currentPage-1) *15 ){
    //     // return "-110%";
    //     setTimeout(() => {
    //       setLeftPos("-110%");
    //     }, idx * 50)
    //   } else {
    //     setTimeout(() => {
    //       setLeftPos("0%");
    //     }, idx * 50)
    //     // return "0%";
    //   }
    // }

    useEffect(() => {
        if (idx === 0) {
            maxValue > 0 ? setLeftPos('-5%') : setLeftPos('0%');
        } else if (idx >= currentPage * NUM_OF_PLAYERS_PER_PAGE) {
            setTimeout(() => {
                setLeftPos('120%');
            }, idx * 10);
        } else if (idx < (currentPage - 1) * NUM_OF_PLAYERS_PER_PAGE) {
            setTimeout(() => {
                setLeftPos('-120%');
            }, idx * 10);
        } else {
            setTimeout(() => {
                setLeftPos('0%');
            }, (idx + 10) * 10);
        }
    }, [currentPage, idx]);

    return (
        <div
            className={classes.line}
            style={{
                backgroundColor: isTopPlayer ? '#f01e9b' : '',
                width: isTopPlayer ? '110%' : '100%',
                // left: isTopPlayer ? '-5%' : '',
                // left: calcLeftPos(idx, currentPage),
                left: leftPos,
                borderRadius: isTopPlayer ? '50px' : '',
                height: `calc(100% / 15)`,
                top: `calc(${playerTopIdx} * calc(100% / 15))`,
            }}
        >
            <div
                className={classes.lineWrapper}
                style={{ width: isTopPlayer ? '90%' : '100%' }}
            >
                <div className={classes.leaderNumberContainer}>
                    <div
                        className={classes.leaderNumber}
                        style={{ color: isTopPlayer ? '#ffee00' : '#6f1bd0' }}
                    >
                        {idx + 1}
                    </div>
                </div>

                <Icon
                    icons={icons}
                    item={item}
                    theBest={isTopPlayer}
                />

                <div className={classes.barWrapper}>
                    <div
                        className={classes.bar}
                        style={{
                            width: `${playerBarWidth}%`,
                            backgroundColor: isTopPlayer
                                ? '#ffee00'
                                : '#f01e9b',
                        }}
                    ></div>
                </div>

                <div
                    className={classes.score}
                    style={{
                        width: scoreWidth,
                        color: isTopPlayer ? '#ffee00' : '#6f1bd0',
                    }}
                >
                    {item.value}
                </div>

                <div className={classes.lightningIconContainer}>
                    <img
                        className={classes.lightningIcon}
                        src={lightningIcon}
                        alt="lightning icon"
                    />
                </div>
            </div>
        </div>
    );
};

export default Player;
