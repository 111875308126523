import { ADD_ALERT } from '../actions/stickyAlert.action';

const initialState = {
    alert: '',
};

export const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALERT:
            return {
                ...state,
                alert: action.payload,
            };
        default:
            return state;
    }
};
