import React from 'react';
import { connect } from 'react-redux';
import { setPlayersForChallenge } from '../../../store/actions/sse.action';
// import ReactHowler from 'react-howler';
// import audio from '../../../assets/default/audios/transition-audio.mp3';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
// import ReactPlayer from 'react-player';
import { setCurrentGame } from '../common';
import { getBestOfTheDay } from '../../../store/actions/ranking.action';
import { getBirthdayAPI } from '../../../store/thunks/thunk';

import { default as RankingView } from './RankingView';

class Ranking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: 'down',
            showLeaderboard: 'visible',
            showTransition: 'hidden',
            playAudio: false,
            video: '',
            playVideo: false,
            birthdayInitialized: false,
        };
        this.prepareForChallenge = this.prepareForChallenge.bind(this);
    }

    challenges = [
        {
            name: 'flash-test',
            videoDuration: 32000,
            video: 'videos/flashTest.mp4',
        },
        {
            name: 'happy-time',
            videoDuration: 32000,
            video: 'videos/happyTime.mp4',
        },
        {
            name: 'airtime',
            videoDuration: 32000,
            video: 'videos/airTime.mp4',
        },
    ];

    url = document.URL.substring(document.URL.lastIndexOf('/') + 1);

    requestBestOfTheDay;
    startTransition;
    showLightening;
    playAudio;
    playVideo;
    goToChallenge;

    prepareForChallenge(payload, challenges) {
        const { gameId, startTime } = payload;
        challenges.map((challenge) => {
            if (challenge.name === gameId) {
                const start = moment(startTime);
                const now = moment();
                const timeToStartChallenge = start.diff(now);
                const timeToStartAnimation =
                    timeToStartChallenge - challenge.videoDuration;
                this.startTransition = new Promise((res, rej) => {
                    this.showLightening = setTimeout(() => {
                        this.setState({
                            showTransition: 'visible',
                        });
                        return res();
                    }, timeToStartAnimation);
                })
                    .then(() => {
                        return new Promise((res, rej) => {
                            this.playAudio = setTimeout(() => {
                                this.setState({
                                    playAudio: true,
                                });
                                return res();
                            }, 300);
                        });
                    })
                    .then(() => {
                        return new Promise((res, rej) => {
                            this.playVideo = setTimeout(() => {
                                this.setState({
                                    showTransition: 'hidden',
                                    playAudio: false,
                                    showLeaderboard: 'hidden',
                                    video: challenge.video,
                                    playVideo: true,
                                });
                                return res();
                            }, 500);
                        });
                    });
            }
        });
    }

    componentDidMount() {
        this.props.getBirthdayAPI();
        this.props.getBestOfTheDay();
        this.requestBestOfTheDay = setInterval(() => {
            this.props.getBestOfTheDay();
        }, 60000);
    }

    componentDidUpdate(prevProps) {
        // if (
        //     this.props.currentGame.gameId !== 'simple-game' &&
        //     this.props.currentGame.gameId !== 'birthday-game'
        // ) {
        //     const { importedPlayers } = this.props;
        //     setCurrentGame(
        //         this.props.currentGame,
        //         this.url,
        //         this.props.history,
        //         importedPlayers,
        //         this.props.setPlayersForChallenge
        //     );
        // } else if (
        //     this.props.announcementGamePayload.gameId !==
        //     prevProps.announcementGamePayload.gameId
        // ) {
        //     const { announcementGamePayload, importedPlayers } = this.props;
        //     announcementGamePayload.gameId &&
        //         announcementGamePayload.gameId !== 'simple-game' &&
        //         announcementGamePayload.gameId !== 'birthday-game' &&
        //         this.prepareForChallenge(
        //             announcementGamePayload,
        //             this.challenges,
        //             importedPlayers
        //         );
        // } else
        if (
            !this.state.birthdayInitialized &&
            this.props.currentGame.gameId === 'birthday-game'
        ) {
            this.props.getBirthdayAPI();
            this.setState({ birthdayInitialized: true });
        } else if (
            this.state.birthdayInitialized &&
            this.props.currentGame.gameId !== 'birthday-game'
        ) {
            this.setState({ birthdayInitialized: false });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.startTransition);
        clearTimeout(this.showLightening);
        clearTimeout(this.playAudio);
        clearTimeout(this.playVideo);
        clearInterval(this.requestBestOfTheDay);
    }

    render() {
        const { height } = this.props;
        const { video, playVideo, showLeaderboard, playAudio, showTransition } =
            this.state;
        return (
            <>
                {/* <ReactPlayer
                    url={'assets/videos/flashTest.mp4'}
                    width='0vw'
                    height='0vh'
                    playing={true}
                    muted={true}
                />
                <ReactPlayer
                    url={'assets/videos/happyTime.mp4'}
                    width='0vw'
                    height='0vh'
                    playing={true}
                    muted={true}
                />
                <ReactPlayer
                    url={'assets/videos/airTime.mp4'}
                    width='0vw'
                    height='0vh'
                    playing={true}
                    muted={true}
                />
                <ReactPlayer
                    className={classes.video}
                    url={video}
                    width='100vw'
                    height='100vh'
                    playing={playVideo}
                />
                <ReactHowler
                    src={audio}
                    playing={playAudio}
                /> */}
                <RankingView
                    height={height}
                    showLeaderboard={showLeaderboard}
                    showTransition={showTransition}
                />
            </>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        importedPlayers: state.sseReducer.players,
        currentGame: state.sseReducer.currentGame,
        announcementGamePayload: state.sseReducer.announcementGamePayload,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPlayersForChallenge: (players) => {
            dispatch(setPlayersForChallenge(players));
        },
        getBestOfTheDay: () => {
            // dispatch(getBestOfTheDay());
        },
        getBirthdayAPI: () => {
            dispatch(getBirthdayAPI());
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Ranking)
);
