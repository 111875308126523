/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import theme from './theme/theme';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Ranking from './components/Leaderboards/Ranking/Ranking';
import HappyTime from './components/Leaderboards/HappyTime/HappyTime';
import AirTime from './components/Leaderboards/AirTime/AirTime';
import FlashTest from './components/Leaderboards/FlashTest/FlashTest';
import AdminPanel from './components/AdminPanel/AdminPanel';
import BirthdayPanel from './components/BirthdayPanel/BirthdayPanel';
import SingleDiploma from './components/Diploma/SingleDiploma';
import SingleDiplomaWithoutTemplate from './components/Diploma/DiplomasWithoutTemplate/SingleDiplomaWithoutTemplate';
import ContainerStickyAlert from './components/ContainerStickyAlert/ContainerStickyAlert';
import PageNotFound from './components/PageNotFound/PageNotFound';
import { useDispatch } from 'react-redux';
import {
    openSSEMain,
    openSSEGames,
} from './store/actions/sse.action';
import { openSSEBandScan } from './store/actions/scanBand.action.js';
import NinjaTag from './components/TagHeroez/NinjaTag/NinjaTag';
import HeroPoints from './components/TagHeroez/HeroPoints/HeroPoints';
import FinalTransition from './components/TagHeroez/FinalTransition/FinalTransition';
// import { delay } from '@redux-saga/core/effects';
// import { wait } from '@testing-library/dom';

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        const aspectRatio = window.innerWidth / 1060;
        document.getElementsByTagName('html')[0].style.fontSize = `${
            25 * aspectRatio
        }px`;
    });

    useEffect(() => {
        dispatch(openSSEMain());
    }, [dispatch]);

    useEffect(() => {
        dispatch(openSSEGames());
    }, [dispatch]);

    useEffect(() => {
        if (window.location.href.includes('ninjatag')) {
            dispatch(openSSEBandScan());
        }
    }, [dispatch]);

    const [height, setHeight] = useState(0);

    useEffect(() => {
        const el = document.body;
        const style = window
            .getComputedStyle(el, null)
            .getPropertyValue('font-size');

        const bodyFontSize = Math.floor(parseFloat(style));
        const coefficient = 72 / 25;
        setHeight(bodyFontSize * coefficient);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <ContainerStickyAlert>
                <Switch>
                    <Route
                        exact
                        path="/simple-game"
                        render={(routeProps) => (
                            <Ranking height={height} {...routeProps} />
                        )}
                    />
                    <Route
                        exact
                        path="/flash-test"
                        render={(routeProps) => (
                            <FlashTest height={height} {...routeProps} />
                        )}
                    />
                    <Route
                        exact
                        path="/happy-time"
                        render={(routeProps) => (
                            <HappyTime height={height} {...routeProps} />
                        )}
                    />
                    <Route
                        exact
                        path="/airtime"
                        render={(routeProps) => (
                            <AirTime height={height} {...routeProps} />
                        )}
                    />
                    <Route exact path="/admin" component={AdminPanel} />
                    <Route
                        exact
                        path="/admin/birthday"
                        component={BirthdayPanel}
                    />
                    <Route
                        exact
                        path="/diploma"
                        render={() => <SingleDiploma />}
                    />
                    <Route
                        exact
                        path="/diplomawithouttemplate"
                        render={() => <SingleDiplomaWithoutTemplate />}
                    />
                    <Route
                        exact
                        path="/ninjatag/scan"
                        render={() => <NinjaTag />}
                    />
                    <Route
                        exact
                        path="/ninjatag/hero-points"
                        render={() => <HeroPoints />}
                    />
                    <Route
                        exact
                        path="/ninjatag/final-transition"
                        render={() => <FinalTransition />}
                    />
                    <Route render={() => <PageNotFound />} />
                </Switch>
            </ContainerStickyAlert>
        </ThemeProvider>
    );
}

export default App;
