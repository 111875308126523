import React, { useState, useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import classes from './NinjaTag.module.css';
//import standby from '/locale/videos/ninjatag_STANDBY.mp4';
// import transition from '../../../assets/default/images/tag-heroez/ninjatag_PRZEJSCIE.webp';
// import loading from '../../../assets/default/images/tag-heroez/ninjatag_LADOWANIE.webp';
import transition from '../../../assets/default/gif/ninjatag_PRZEJSCIE.gif';
import loading from '../../../assets/default/videos/ninjatag_LADOWANIE.mp4';
import { setBandScanned } from '../../../store/actions/scanBand.action.js';
import { getUserFromBandAPI } from '../../../store/thunks/thunk.js';

const NinjaTag = (props) => {
    const history = useHistory();
    const [activeTransition, setActiveTransition] = useState('hidden');
    const [activeLoading, setActiveLoading] = useState('hidden');
    // const [loadingGifStr, setLoadingGifStr] = useState('');
    const [trigger, setTrigger] = useState(false);

    const loadingRef = useRef(null);
    const standbyRef = useRef(null);

    const playerID = props.playerID;
    const bandScanned = props.bandScanned;
    const setBandScanned = props.setBandScanned;
    const getUserFromBandAPI = props.getUserFromBandAPI;

    useEffect(() => {
        // setLoadingGifStr(loading + "?x=" + Math.random());
        if (trigger) {
            setActiveLoading('visible');
            setTimeout(() => {
                loadingRef.current.play();
                standbyRef.current.pause();
            }, 100);
            setTimeout(() => {
                setActiveTransition('visible');
            }, 4100);
            setTimeout(() => {
                setActiveLoading('hidden');
                // setActiveTransition('hidden');
                // setLoadingGifStr('');
                loadingRef.current.pause();
                // setTrigger(false);
                history.push('/ninjatag/hero-points');
            }, 5050);
        }
    }, [trigger]);

    useEffect(() => {
        if (bandScanned && playerID) {
            // setActiveLoading('visible');
            setTrigger(true);
            setBandScanned(false);
            getUserFromBandAPI(playerID);
        }
    }, [bandScanned]);

    return (
        <div className={classes.wrapper}>
            <video
                className={classes.imageWrapper}
                ref={standbyRef}
                autoPlay
                loop
                muted
                onClick={() => setTrigger(true)}
            >
                <source
                    src={'/locale/videos/ninjatag_STANDBY.mp4'}
                    type="video/mp4"
                />
            </video>
            <video
                ref={loadingRef}
                className={classes.loading}
                muted
                style={{ visibility: activeLoading }}
            >
                <source src={loading} type="video/mp4" />
            </video>
            {/*
            <img
                src={standby}
                alt="Standby gif"
                className={classes.imageWrapper}
                onClick={() => {
                    setActiveLoading('visible');
                }}
            />
            <img
                src={loadingGifStr}
                alt="Loading gif"
                className={classes.loading}
                style={{ visibility: activeLoading }}
            />
            */}
            <img
                src={transition}
                alt="Transition gif"
                className={classes.transition}
                style={{ visibility: activeTransition }}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        playerID: state.scanBand.bandID,
        bandScanned: state.scanBand.bandScanned,
    };
};

const mapDistpachToProps = (dispatch) => {
    return {
        setBandScanned: (scanned) => {
            dispatch(setBandScanned(scanned));
        },
        getUserFromBandAPI: (bandID) => {
            dispatch(getUserFromBandAPI(bandID));
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDistpachToProps)(NinjaTag)
);
