import {useEffect} from 'react';
import {wrapComponent} from 'react-snackbar-alert';
import {useSelector} from 'react-redux';

const ContainerStickyAlert = wrapComponent(function({ createSnackbar, children }) {

  const alert = useSelector(state => state.alert.alert)

  useEffect(() => {
    if (alert){
      createSnackbar({
        message:  'Error',
        dismissable: false,
        pauseOnHover: true,
        progressBar: false,
        sticky: false,
        timeout: 2000,
        theme: 'error'
      });
    }
  }, [alert, createSnackbar])

  return children;
  }
)

export default ContainerStickyAlert;