import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import adminPanelReducer from './store/reducers/adminPanel.reducer';
import { Provider } from 'react-redux';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './store/sagas/rootSaga';
import { SnackbarProvider } from 'react-snackbar-alert';
import { alertReducer } from './store/reducers/stickyAlert.reducer';
import sseMiddleware from './middleware/sse.middleware';
import sseReducer from './store/reducers/sse.reducer';
import { rankingReducer } from './store/reducers/ranking.reducer';
import thunk from 'redux-thunk';
import scanBandReducer from './store/reducers/scanBand.reducer';

const rootReducer = combineReducers({
    adminPanel: adminPanelReducer,
    alert: alertReducer,
    sseReducer: sseReducer,
    ranking: rankingReducer,
    scanBand: scanBandReducer,
});

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware, sseMiddleware];

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__
        ? compose(
              applyMiddleware(...middleware, thunk),
              window.__REDUX_DEVTOOLS_EXTENSION__()
          )
        : applyMiddleware(...middleware, thunk)
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <SnackbarProvider position={'bottom-left'}>
        <React.StrictMode>
            <BrowserRouter>
                <Provider store={store}>
                    <App />
                </Provider>
            </BrowserRouter>
        </React.StrictMode>
    </SnackbarProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
