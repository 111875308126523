import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    resetButton: {
      fontFamily: 'Saira-Regular',
      background: '#ec0f9e',
      color: 'white',
      fontSize: '23px',
      width: '60%',
      padding: '7px 10px 2px 10px',
      border: '2px solid #ec0f9e',
      "&:hover":{
        color: '#ec0f9e',
      }
    },
    printButton: {
      fontFamily: 'Saira-Regular',
      color: 'white',
      fontSize: '23px',
      background: '#00ebc6',
      padding: '7px 10px 2px 10px',
      width: '60%',
      border: '2px solid #00ebc6',
      "&:hover":{
        color: '#00ebc6',
      }
    },
    tableCell: {
      color: '#1e1138',
      fontFamily: 'Saira-Regular',
      fontSize: '28px',
      width: '5%'
    },
    icon: {
      width: "50px",
    }
  });

