import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { ModalStyle, Overlay, ModalWrapper } from "./Modal.style";


const modalRoot = document.querySelector("#modal-root");

const Modal = ({ className, isOpen, onClose, children }) => {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <Overlay />
      <ModalStyle className={className}>
        <ModalWrapper>
          {children}
        </ModalWrapper>
      </ModalStyle>
    </>,
    modalRoot
  );
};

export default Modal;
