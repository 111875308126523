import React from 'react';
import classes from './DiplomaWithoutTemplate.module.css';
import header from '../../../assets/default/images/diploma/header.png';
// import Jumpilot from '../../../assets/default/images/diploma/JUMPILOT_LOGO.png'
import cup from '../../../assets/default/images/diploma/cup.png';
import { useSelector } from 'react-redux';
import time from '../../../assets/default/images/diploma/time-icon.png';
import jump from '../../../assets/default/images/diploma/jumps-icon.png';
// import comeAgainImg from '../../../assets/default/images/diploma/comeAgain.png'
import QRCode from 'react-qr-code';
import scanAndCollect from '../../../assets/default/images/diploma/scan-collect.png';
import { addSpacesInNumbers } from '../../Leaderboards/common/addSpacesInNumbers';
import elements from '../../TagHeroez/HeroPoints/elements';

export default function DiplomaSample({
    id,
    jumps,
    score,
    flightTime,
    selectedBandQRCode,
}) {
    const result = jumps + score;

    const icons = useSelector((state) => state.adminPanel.icons);

    return (
        <div className={classes.diplomaWrapper}>
            <img src={header} alt="Certificate" className={classes.headerImg} />
            <img src={cup} alt="cup" className={classes.cupImg} />
            <table className={classes.diplomaTable}>
                <tbody>
                    <tr className={classes.tableRow}>
                        <td className={classes.firstColumn}>
                            <img
                                src={elements[id]?.icon}
                                className={classes.userIconImg}
                                alt="User Icon"
                            />
                        </td>
                        <td className={classes.firstParagraph}>RESULT</td>
                        <td className={classes.firstNumber}>
                            {addSpacesInNumbers(result)}
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td className={classes.firstColumn}>
                            <img
                                className={classes.diplomaIconImg}
                                src={jump}
                                alt="Jump"
                            />
                        </td>
                        <td className={classes.paragraph}>JUMPS</td>
                        <td className={classes.number}>
                            {addSpacesInNumbers(jumps)}
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td className={classes.firstColumn}>
                            <img
                                className={classes.diplomaIconImg}
                                src={time}
                                alt="Clock"
                            />
                        </td>
                        <td className={classes.paragraph}>{'AIRTIME (sec)'}</td>
                        <td className={classes.number}>
                            {addSpacesInNumbers(flightTime)}
                        </td>
                    </tr>
                </tbody>
            </table>
            <img
                alt={'scan and collect'}
                src={scanAndCollect}
                className={classes.scanAndCollect}
            />
            <div className={classes.qrWrapper}>
                <QRCode
                    value={selectedBandQRCode}
                    size={130}
                    includeMargin={true}
                />
            </div>
        </div>
    );
}
