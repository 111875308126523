import React from "react";

export const findIcon = (icons, item, height, classes) => {
  let icon = '';
  const iconId = Number(item.id) - 1;
  if (icons[iconId]){
    icon = icons[iconId].normalIcon;
  }
  const iconHeight = height * 0.75;
  return <img
    className={classes.icon}
    style={{height: iconHeight, width: height * 0.75}}
    src={icon}
    alt="Icon"
  />
}