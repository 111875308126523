import React from 'react';
import classes from './FlashTest.module.css';
import { connect } from 'react-redux';
import Logo from '../../../assets/default/images/ranking/JUMPILOTLOGO.png';
import Tick from '../../../assets/default/images/FlashTest/tick.png';
import GoldMedal from '../../../assets/default/images/FlashTest/goldMedal.png';
import SilverMedal from '../../../assets/default/images/FlashTest/silverMedal.png';
import BronzeMedal from '../../../assets/default/images/FlashTest/bronzeMedal.png';
import Congrats from '../../../assets/default/images/FlashTest/congrats.png';
import explosion from '../../../assets/default/images/FlashTest/wybuch_wyniki.gif';
import ReactHowler from 'react-howler';
import audio from '../../../assets/default/audios/transition-audio.mp3';
import transition from '../../../assets/default/images/FlashTest/lightning-transition.gif';
import { withRouter } from 'react-router-dom';
import { resetAnnouncement } from '../../../store/actions/sse.action';
import { setCurrentGame, twentyLines, countOccurrences } from '../common';
import { dynamicSort } from '../../../common/dynamicSort';
import {
    fullBarYellow,
    barBackgroundLightGreen,
    fullBarBackgroundDeepPink,
    fullBarBackgroundLightPink,
} from '../common/colors';
import { findIcon } from '../common/findIconInChallenges';

class FlashTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: 'down',
            countDown: 0,
            showExplosion: 'visible',
            showScore: 'hidden',
            showTransition: 'hidden',
            playAudio: false,
        };
    }

    decrement;
    timeOut1;
    timeOut2;
    showLightening;
    playAudio;
    goHome;

    maxJumps = 30;

    url = document.URL.substring(document.URL.lastIndexOf('/') + 1);

    componentDidMount() {
        const currentGame = setCurrentGame(
            this.props.currentGame,
            this.url,
            this.props.history
        );
        this.setState({ countDown: currentGame });

        this.decrement = setInterval(() => {
            if (this.state.countDown > -2) {
                this.setState({ countDown: this.state.countDown - 1 });
            } else if (this.state.countDown === -2) {
                this.setState({ countDown: this.state.countDown - 1 });
                this.setState({ showExplosion: 'hidden' });
                this.setState({ showScore: 'visible' });
            } else if (this.state.countDown > -5) {
                this.setState({ countDown: this.state.countDown - 1 });
            } else if (this.state.countDown === -5) {
                clearInterval(this.decrement);
                this.timeOut1 = new Promise((res, rej) => {
                    this.setState({
                        showTransition: 'visible',
                    });
                    return res();
                })
                    .then(() => {
                        this.timeOut2 = new Promise((res, rej) => {
                            this.playAudio = setTimeout(() => {
                                this.setState({
                                    playAudio: true,
                                });
                                return res();
                            }, 300);
                        });
                    })
                    .then(() => {
                        this.goHome = setTimeout(() => {
                            this.props.resetAnnouncement();
                            this.props.history.push('/');
                        }, 2000);
                    });
            }
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentGame.gameId !== prevProps.currentGame.gameId) {
            const currentGame = setCurrentGame(
                this.props.currentGame,
                this.url,
                this.props.history
            );
            this.setState({ countDown: currentGame });
        }
    }

    componentWillUnmount() {
        clearInterval(this.decrement);
        clearTimeout(this.timeOut1);
        clearTimeout(this.timeOut2);
        clearTimeout(this.showLightening);
        clearTimeout(this.playAudio);
        clearTimeout(this.goHome);
    }

    render() {
        const { height } = this.props;
        const { countDown, showExplosion, showScore } = this.state;
        const players = this.props.challengePlayers;
        const copy = [...players];
        const scores = [];
        const sortedPlayers = players.sort(dynamicSort('jumps'));

        return (
            <div className={classes.leaderboardWrapper}>
                <ReactHowler src={audio} playing={this.state.playAudio} />
                <img
                    src={transition}
                    alt="transition"
                    className={classes.transition}
                    style={{ visibility: this.state.showTransition }}
                />
                <div className={classes.containerWrapper}>
                    <div
                        className={classes.headerWrapper}
                        style={{ height: `${height * 3.5}px` }}
                    >
                        {countDown >= 0 ? (
                            <div className={classes.countdown}>{countDown}</div>
                        ) : (
                            <div className={classes.brawo}>
                                <img src={Congrats} alt="CONGRATS!" />
                            </div>
                        )}
                    </div>
                    <div
                        className={classes.resultsBackground}
                        style={{
                            height: `${
                                players.length <= 20
                                    ? height * 20
                                    : height * players.length
                            }px`,
                        }}
                    >
                        {twentyLines.map((line, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={classes.resultsBackgroundLine}
                                    style={{
                                        background: `${
                                            idx % 2 === 0 &&
                                            barBackgroundLightGreen
                                        }`,
                                        height: `${height}px`,
                                        width: '100%',
                                        top: `${height * idx}px`,
                                    }}
                                />
                            );
                        })}
                        {sortedPlayers && (
                            <div
                                className={classes.results}
                                style={{
                                    height: `${height * players.length}px`,
                                }}
                            >
                                {sortedPlayers.map((item, idx) => {
                                    scores.push(item.jumps);
                                    const icon = findIcon(
                                        this.props.icons,
                                        item,
                                        height,
                                        classes
                                    );
                                    return (
                                        <div
                                            key={idx}
                                            className={classes.line}
                                            style={{
                                                height: `${height}px`,
                                                background: `${
                                                    item.jumps >= this.maxJumps
                                                        ? idx % 2 === 0
                                                            ? fullBarBackgroundDeepPink
                                                            : fullBarBackgroundLightPink
                                                        : idx % 2 === 0 &&
                                                          barBackgroundLightGreen
                                                }`,
                                            }}
                                        >
                                            <div
                                                className={classes.leaderNumber}
                                                style={{
                                                    height: `${height * 0.7}px`,
                                                    width: `${height * 0.7}px`,
                                                    backgroundImage: `url( ${
                                                        countDown < -1 &&
                                                        item.jumps >=
                                                            this.maxJumps &&
                                                        ((item.jumps === 150 &&
                                                            GoldMedal) ||
                                                            (item.jumps ===
                                                                125 &&
                                                                SilverMedal) ||
                                                            (item.jumps ===
                                                                100 &&
                                                                BronzeMedal))
                                                    }     
                                                                           )`,
                                                }}
                                            >
                                                <div className={classes.digit}>
                                                    {idx + 1}
                                                </div>
                                            </div>
                                            <div
                                                key={idx}
                                                className={classes.lineWrapper}
                                                style={{
                                                    top: `calc(${
                                                        height *
                                                            copy.indexOf(
                                                                copy.filter(
                                                                    (i) =>
                                                                        i.jumps ===
                                                                        item.jumps
                                                                )[0]
                                                            ) +
                                                        height *
                                                            (countOccurrences(
                                                                scores,
                                                                item.jumps
                                                            ) -
                                                                1)
                                                    }px)`,
                                                    height: `${height}px`,
                                                }}
                                            >
                                                {icon}
                                                <div
                                                    className={
                                                        classes.barWrapper
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.backgroundBarWrapper
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.backgroundBar
                                                            }
                                                        />
                                                        <div
                                                            className={
                                                                classes.backgroundTriangle
                                                            }
                                                            style={{
                                                                borderRightWidth:
                                                                    height *
                                                                    0.4,
                                                                borderTopWidth:
                                                                    height *
                                                                    0.4,
                                                                borderLeftWidth: 0,
                                                                borderBottomWidth: 0,
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.scoreBarWrapper
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.scoreBar
                                                            }
                                                            style={{
                                                                width: `${
                                                                    (100 /
                                                                        this
                                                                            .maxJumps) *
                                                                    item.jumps
                                                                }%`,
                                                                backgroundColor: `${
                                                                    item.jumps >=
                                                                        this
                                                                            .maxJumps &&
                                                                    fullBarYellow
                                                                }`,
                                                            }}
                                                        />
                                                        <div
                                                            className={
                                                                classes.scoreTriangle
                                                            }
                                                            style={{
                                                                borderColor: `${
                                                                    item.jumps >=
                                                                        this
                                                                            .maxJumps &&
                                                                    `${fullBarYellow} transparent transparent transparent`
                                                                }`,
                                                                borderRightWidth:
                                                                    height *
                                                                    0.4,
                                                                borderTopWidth:
                                                                    height *
                                                                    0.4,
                                                                borderLeftWidth: 0,
                                                                borderBottomWidth: 0,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        lineHeight: `${height}px`,
                                                    }}
                                                    className={classes.score}
                                                >
                                                    {countDown > -2 ? (
                                                        item.jumps <
                                                        this.maxJumps ? (
                                                            <div
                                                                className={
                                                                    classes.scorePoints
                                                                }
                                                            >
                                                                {this.maxJumps -
                                                                    item.jumps}
                                                            </div>
                                                        ) : (
                                                            <img
                                                                className={
                                                                    classes.tick
                                                                }
                                                                style={{
                                                                    height:
                                                                        height *
                                                                        0.65,
                                                                    width:
                                                                        height *
                                                                        0.65,
                                                                }}
                                                                src={Tick}
                                                                alt="tick"
                                                            />
                                                        )
                                                    ) : (
                                                        <>
                                                            <img
                                                                src={explosion}
                                                                alt="explosion"
                                                                className={
                                                                    classes.explosion
                                                                }
                                                                style={{
                                                                    height:
                                                                        height *
                                                                        1.2,
                                                                    width:
                                                                        height *
                                                                        1.2,
                                                                    visibility: `${showExplosion}`,
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    visibility: `${showScore}`,
                                                                }}
                                                                className={
                                                                    classes.scoreNumber
                                                                }
                                                            >
                                                                {item.jumps >=
                                                                this.maxJumps
                                                                    ? (item.jumps ===
                                                                          150 &&
                                                                          '+150') ||
                                                                      (item.jumps ===
                                                                          125 &&
                                                                          '+125') ||
                                                                      (item.jumps ===
                                                                          100 &&
                                                                          '+100') ||
                                                                      '+50'
                                                                    : 0}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div
                        className={classes.logoContainer}
                        style={{ height: `${height * 2.5}px` }}
                    >
                        <img
                            className={classes.logo}
                            src={Logo}
                            alt="GoJump Logo"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        icons: state.adminPanel.icons,
        challengePlayers: state.sseReducer.challengePlayers,
        currentGame: state.sseReducer.currentGame,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetAnnouncement: () => {
            dispatch(resetAnnouncement());
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FlashTest)
);
