import React from 'react';
import classes from './RankingHeader.module.css';

const RankingHeader = ({ headerTitle }) => {
    return (
        <div className={classes.headerWrapper}>
            <div className={classes.rankingHeader}>
                <div className={classes.headerTitle}>
                    {headerTitle.toUpperCase()}
                </div>
            </div>
        </div>
    );
};

export default RankingHeader;
