import {
    GET_ALL_JUMPS_AND_USERS,
    setAllJumpsAndUsers,
    GET_RESULTS,
    setResults,
    GET_QR_CODE,
    setQRCode,
} from '../actions/adminPanel.action';
import { call, takeEvery, put } from 'redux-saga/effects';
import { addAlert } from '../actions/stickyAlert.action';
import { adminPanelApi } from '../../api/api';
import { openSSEMain } from '../actions/sse.action';

export function* bandsSaga() {
    yield takeEvery(GET_ALL_JUMPS_AND_USERS, function* () {
        try {
            const response = yield call(adminPanelApi.getAllJumpsAndUsers);
            yield put(setAllJumpsAndUsers(response.data));
        } catch (error) {
            yield put(addAlert(error));
            yield put(openSSEMain());
        }
    });
    yield takeEvery(GET_RESULTS, function* () {
        try {
            const response = yield call(adminPanelApi.getAllBands);
            yield put(setResults(response.data));
        } catch (error) {
            yield put(addAlert('Something went wrong'));
            yield put(openSSEMain());
        }
    });
    yield takeEvery(GET_QR_CODE, function* (action) {
        try {
            const response = yield call(
                adminPanelApi.getQRCode,
                action.payload
            );
            console.log('QR code', response.data);
            yield put(setQRCode(response.data));
        } catch (error) {
            yield put(addAlert('Something went wrong with qr code'));
            yield put(openSSEMain());
        }
    });
}
