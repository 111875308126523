import {
    RESET_QR_CODE,
    SET_ALL_JUMPS_AND_USERS,
    SET_QR_CODE,
    SET_RESULTS,
    SET_SELECTED_BAND_ID,
    START_BIRTHDAY,
    STOP_BIRTHDAY,
} from '../actions/adminPanel.action';
import icon1 from '../../assets/default/images/icons/1.png';
import icon2 from '../../assets/default/images/icons/2.png';
import icon3 from '../../assets/default/images/icons/3.png';
import icon4 from '../../assets/default/images/icons/4.png';
import icon5 from '../../assets/default/images/icons/5.png';
import icon6 from '../../assets/default/images/icons/6.png';
import icon7 from '../../assets/default/images/icons/7.png';
import icon8 from '../../assets/default/images/icons/8.png';
import icon9 from '../../assets/default/images/icons/9.png';
import icon10 from '../../assets/default/images/icons/10.png';
import icon11 from '../../assets/default/images/icons/11.png';
import icon12 from '../../assets/default/images/icons/12.png';
import icon13 from '../../assets/default/images/icons/13.png';
import icon14 from '../../assets/default/images/icons/14.png';
import icon15 from '../../assets/default/images/icons/15.png';
import icon16 from '../../assets/default/images/icons/16.png';
import icon17 from '../../assets/default/images/icons/17.png';
import icon18 from '../../assets/default/images/icons/18.png';
import icon19 from '../../assets/default/images/icons/19.png';
import icon20 from '../../assets/default/images/icons/20.png';
import icon21 from '../../assets/default/images/icons/21.png';
import icon22 from '../../assets/default/images/icons/22.png';
import icon23 from '../../assets/default/images/icons/23.png';
import icon24 from '../../assets/default/images/icons/24.png';
import icon25 from '../../assets/default/images/icons/25.png';
import icon26 from '../../assets/default/images/icons/26.png';
import icon27 from '../../assets/default/images/icons/27.png';
import icon28 from '../../assets/default/images/icons/28.png';
import icon29 from '../../assets/default/images/icons/29.png';
import icon30 from '../../assets/default/images/icons/30.png';
import icon31 from '../../assets/default/images/icons/31.png';
import icon32 from '../../assets/default/images/icons/32.png';
import icon33 from '../../assets/default/images/icons/33.png';
import icon34 from '../../assets/default/images/icons/34.png';
import icon35 from '../../assets/default/images/icons/35.png';
import icon36 from '../../assets/default/images/icons/36.png';
import icon37 from '../../assets/default/images/icons/37.png';
import icon38 from '../../assets/default/images/icons/38.png';
import icon39 from '../../assets/default/images/icons/39.png';
import icon40 from '../../assets/default/images/icons/40.png';

import iconCrown1 from '../../assets/default/images/icons-crown/1.png';
import iconCrown2 from '../../assets/default/images/icons-crown/2.png';
import iconCrown3 from '../../assets/default/images/icons-crown/3.png';
import iconCrown4 from '../../assets/default/images/icons-crown/4.png';
import iconCrown5 from '../../assets/default/images/icons-crown/5.png';
import iconCrown6 from '../../assets/default/images/icons-crown/6.png';
import iconCrown7 from '../../assets/default/images/icons-crown/7.png';
import iconCrown8 from '../../assets/default/images/icons-crown/8.png';
import iconCrown9 from '../../assets/default/images/icons-crown/9.png';
import iconCrown10 from '../../assets/default/images/icons-crown/10.png';
import iconCrown11 from '../../assets/default/images/icons-crown/11.png';
import iconCrown12 from '../../assets/default/images/icons-crown/12.png';
import iconCrown13 from '../../assets/default/images/icons-crown/13.png';
import iconCrown14 from '../../assets/default/images/icons-crown/14.png';
import iconCrown15 from '../../assets/default/images/icons-crown/15.png';
import iconCrown16 from '../../assets/default/images/icons-crown/16.png';
import iconCrown17 from '../../assets/default/images/icons-crown/17.png';
import iconCrown18 from '../../assets/default/images/icons-crown/18.png';
import iconCrown19 from '../../assets/default/images/icons-crown/19.png';
import iconCrown20 from '../../assets/default/images/icons-crown/20.png';
import iconCrown21 from '../../assets/default/images/icons-crown/21.png';
import iconCrown22 from '../../assets/default/images/icons-crown/22.png';
import iconCrown23 from '../../assets/default/images/icons-crown/23.png';
import iconCrown24 from '../../assets/default/images/icons-crown/24.png';
import iconCrown25 from '../../assets/default/images/icons-crown/25.png';
import iconCrown26 from '../../assets/default/images/icons-crown/26.png';
import iconCrown27 from '../../assets/default/images/icons-crown/27.png';
import iconCrown28 from '../../assets/default/images/icons-crown/28.png';
import iconCrown29 from '../../assets/default/images/icons-crown/29.png';
import iconCrown30 from '../../assets/default/images/icons-crown/30.png';
import iconCrown31 from '../../assets/default/images/icons-crown/31.png';
import iconCrown32 from '../../assets/default/images/icons-crown/32.png';
import iconCrown33 from '../../assets/default/images/icons-crown/33.png';
import iconCrown34 from '../../assets/default/images/icons-crown/34.png';
import iconCrown35 from '../../assets/default/images/icons-crown/35.png';
import iconCrown36 from '../../assets/default/images/icons-crown/36.png';
import iconCrown37 from '../../assets/default/images/icons-crown/37.png';
import iconCrown38 from '../../assets/default/images/icons-crown/38.png';
import iconCrown39 from '../../assets/default/images/icons-crown/39.png';
import iconCrown40 from '../../assets/default/images/icons-crown/40.png';

const initialState = {
    allJumps: '',
    allUsers: '',
    selectedBandID: '',
    selectedBandQRCode: '',
    birthday: null,
    results: [],
    icons: [
        { normalIcon: icon1, iconWithCrown: iconCrown1 },
        { normalIcon: icon2, iconWithCrown: iconCrown2 },
        { normalIcon: icon3, iconWithCrown: iconCrown3 },
        { normalIcon: icon4, iconWithCrown: iconCrown4 },
        { normalIcon: icon5, iconWithCrown: iconCrown5 },
        { normalIcon: icon6, iconWithCrown: iconCrown6 },
        { normalIcon: icon7, iconWithCrown: iconCrown7 },
        { normalIcon: icon8, iconWithCrown: iconCrown8 },
        { normalIcon: icon9, iconWithCrown: iconCrown9 },
        { normalIcon: icon10, iconWithCrown: iconCrown10 },
        { normalIcon: icon11, iconWithCrown: iconCrown11 },
        { normalIcon: icon12, iconWithCrown: iconCrown12 },
        { normalIcon: icon13, iconWithCrown: iconCrown13 },
        { normalIcon: icon14, iconWithCrown: iconCrown14 },
        { normalIcon: icon15, iconWithCrown: iconCrown15 },
        { normalIcon: icon16, iconWithCrown: iconCrown16 },
        { normalIcon: icon17, iconWithCrown: iconCrown17 },
        { normalIcon: icon18, iconWithCrown: iconCrown18 },
        { normalIcon: icon19, iconWithCrown: iconCrown19 },
        { normalIcon: icon20, iconWithCrown: iconCrown20 },
        { normalIcon: icon21, iconWithCrown: iconCrown21 },
        { normalIcon: icon22, iconWithCrown: iconCrown22 },
        { normalIcon: icon23, iconWithCrown: iconCrown23 },
        { normalIcon: icon24, iconWithCrown: iconCrown24 },
        { normalIcon: icon25, iconWithCrown: iconCrown25 },
        { normalIcon: icon26, iconWithCrown: iconCrown26 },
        { normalIcon: icon27, iconWithCrown: iconCrown27 },
        { normalIcon: icon28, iconWithCrown: iconCrown28 },
        { normalIcon: icon29, iconWithCrown: iconCrown29 },
        { normalIcon: icon30, iconWithCrown: iconCrown30 },
        { normalIcon: icon31, iconWithCrown: iconCrown31 },
        { normalIcon: icon32, iconWithCrown: iconCrown32 },
        { normalIcon: icon33, iconWithCrown: iconCrown33 },
        { normalIcon: icon34, iconWithCrown: iconCrown34 },
        { normalIcon: icon35, iconWithCrown: iconCrown35 },
        { normalIcon: icon36, iconWithCrown: iconCrown36 },
        { normalIcon: icon37, iconWithCrown: iconCrown37 },
        { normalIcon: icon38, iconWithCrown: iconCrown38 },
        { normalIcon: icon39, iconWithCrown: iconCrown39 },
        { normalIcon: icon40, iconWithCrown: iconCrown40 },
    ],
};

const adminPanelReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_JUMPS_AND_USERS:
            return {
                ...state,
                allJumps: action.payload.jumps,
                allUsers: action.payload.users,
            };
        case START_BIRTHDAY:
            return {
                ...state,
                birthday: action.payload,
            };
        case STOP_BIRTHDAY:
            return {
                ...state,
                birthday: null,
            };
        case SET_SELECTED_BAND_ID:
            return {
                ...state,
                selectedBandID: action.payload,
            };
        case SET_RESULTS:
            return {
                ...state,
                results: action.payload,
            };
        case SET_QR_CODE:
            return {
                ...state,
                selectedBandQRCode: action.payload,
            };
        case RESET_QR_CODE:
            return {
                ...state,
                selectedBandQRCode: '',
            };
        default:
            return state;
    }
};

export default adminPanelReducer;
