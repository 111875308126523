import React from 'react';
import classes from './Player.module.css';

const Icon = ({ icons, item, theBest }) => {
    let icon = '';
    const iconId = Number(item.key);
    if (icons[Number(item.key)]) {
        icon = theBest ? icons[iconId].iconWithCrown : icons[iconId].normalIcon;
    }

    return (
        <div className={classes.iconContainer}>
            <img className={classes.icon} src={icon} alt="Icon" />
        </div>
    );
};

export default Icon;
