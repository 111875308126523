import axios from 'axios';
import {baseUrl} from "./config";

console.log('baseUrl: ', baseUrl);

const instance = axios.create({
    baseURL: baseUrl,
});

export const rankingAPI = {
    getBestOfTheDay() {
        return instance.get('/api/bands/king-of-the-day');
    },
};

export const birthdayAPI = {
    startBirthday(data) {
        return instance.post('/start-game/birthday-game', data);
    },
    stopBirthday() {
        return instance.post('/stop-game/birthday-game');
    },
    resetBirthday() {
        return instance.post('/reset-game/birthday-game');
    },
    getBirthday() {
        return instance.get('/current');
    },
};

export const adminPanelApi = {
    getAllJumpsAndUsers() {
        return instance.get('/api/bands/summary/');
    },
    getAllBands() {
        return instance.get('/api/bands/history/');
    },
    getQRCode(body) {
        return instance.get(`/api/bands/qr-diploma-data/${body.id}`);
    },
};

export const scanBandAPI = {
    // getUserFromBand(bandUUID) {
    //     return instance.get(`/api/bands/session/${bandUUID}`);
    // },
    getAllUsers() {
        return instance.get('/api/bands/history');
    },
};

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);
