import {
    GET_USER_FROM_BAND,
    // GET_BAND_UUID,
    GET_BAND_ID,
    SET_BAND_SCANNED,
} from '../actions/scanBand.action.js';

const initialState = {
    // bandUUID: null,
    bandID: null,
    bandScanned: false,
    userFromBand: {},
};

const scanBandReducer = (state = initialState, action) => {
    switch (action.type) {
        // case GET_BAND_UUID:
        //     return {
        //         ...state,
        //         bandUUID: action.body,
        //     };
        case GET_BAND_ID:
            return {
                ...state,
                bandID: action.body,
            };
        case SET_BAND_SCANNED:
            return {
                ...state,
                bandScanned: action.body,
            };
        case GET_USER_FROM_BAND:
            return {
                ...state,
                userFromBand: action.body,
            };
        default:
            return state;
    }
};

export default scanBandReducer;
