import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import styles from './BirthdayPanelStyles';
import { withStyles } from '@material-ui/styles';
import AdminPanelTableItem from './BirthdayPanelTableItem';
// import { addAlert } from "../../store/actions/stickyAlert.action";
import {
    getAllJumpsAndUsers,
    getResults,
} from '../../store/actions/adminPanel.action';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { withRouter } from 'react-router';
import Modal from '../Modal/Modal';
import {
    getBirthdayAPI,
    startBirthdayAPI,
    stopBirthdayAPI,
    resetBirthdayAPI,
    stopBirthdayAndSaveDataAPI,
} from '../../store/thunks/thunk';
import Jumpilot from '../../assets/default/images/diploma/JUMPILOT_LOGO.png';
import { addSpacesInNumbers } from '../Leaderboards/common/addSpacesInNumbers';

const ZENDESK_KEY = '380e4da7-1b2d-4b32-9297-04c722cd1d8b';

const setting = {
    color: {
        theme: '#6414cc',
        launcherText: '#fff',
    },
    title: 'Chat with us!',
    contactForm: {
        fields: [
            {
                id: 'description',
                prefill: { '*': 'My pre-filled description' },
            },
        ],
    },
};

export const formatSeconds = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor((seconds % 3600) % 60);

    if (min < 10 && sec < 10) {
        return `${hours}:0${min}:0${sec}`;
    }
    if (min < 10) {
        return `${hours}:0${min}:${sec}`;
    }
    if (sec < 10) {
        return `${hours}:${min}:0${sec}`;
    }

    return `${hours}:${min}:${sec}`;
};

class BirthdayPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isStarted: false,
            timer: 0,
            reachToGoal: 0,
            teamName: '',
            isStopModalOpen: false,
            isResetModalOpen: false,
            isBackModalOpen: false,
        };
    }

    getAllJumpsandUsersTimeOut;
    getResultsTimeOut;

    componentDidMount() {
        ZendeskAPI('webWidget', 'toggle');
        this.props.getBirthdayAPI();
        this.props.getAllJumpsandUsers();
        this.props.getResults();
        this.getAllJumpsandUsersTimeOut = setInterval(() => {
            this.props.getAllJumpsandUsers();
        }, 10000);
        this.getResultsTimeOut = setInterval(() => {
            this.props.getResults();
        }, 10000);
        this.handleResetValues = this.handleResetValues.bind(this);
        this.handleStop = this.handleStop.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.birthday !== prevProps.birthday) {
            if (this.props.birthday) {
                const startingTimeUnix = this.props.birthday.startDate
                    ? new Date(this.props.birthday.startDate).getTime()
                    : new Date().getTime();
                this.startTimer = setInterval(() => {
                    const currentTimeUnix = new Date().getTime();
                    const time = Math.floor(
                        (currentTimeUnix + 2000 - startingTimeUnix) / 1000
                    );
                    this.setState({ ...this.state, timer: time });
                }, 1000);
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.getAllJumpsandUsersTimeOut);
        clearInterval(this.getResultsTimeOut);
        clearInterval(this.startTimer);
    }

    handleResetValues() {
        // this.props.stopBirthdayAPI();
        this.props.resetBirthdayAPI();
        this.setState({ ...this.state, isResetModalOpen: false });
        clearInterval(this.startTimer);
        this.props.history.push('/admin/birthday');
    }

    handleBack() {
        // this.props.stopBirthdayAPI();
        this.props.resetBirthdayAPI();
        this.setState({ ...this.state, isBackModalOpen: false });
        this.props.history.push('/admin');
    }

    handleStop() {
        clearInterval(this.startTimer);
        clearInterval(this.getAllJumpsandUsersTimeOut);
        clearInterval(this.getResultsTimeOut);
        this.props.getAllJumpsandUsers();
        this.props.getResults();
        this.setState({ ...this.state, isStopModalOpen: false });
        this.props.stopBirthdayAndSaveDataAPI();
    }

    handleInputChange(property) {
        return (e) => {
            this.setState({
                [property]: e.target.value,
            });
        };
    }

    render() {
        const canStart = this.state.reachToGoal && this.state.teamName;
        ZendeskAPI('webWidget', 'setLocale', 'en');
        const { classes, allResults, history, birthday } = this.props;
        const sortedPlayers = allResults
            .sort((a, b) => b.jumps + b.score - (a.jumps + a.score))
            .filter((el) => el.jumps + el.score > 5);

        return (
            sortedPlayers && (
                <div className={classes.wrapper}>
                    <Zendesk zendeskKey={ZENDESK_KEY} {...setting} />
                    <Box component={Paper} className={classes.box}>
                        <div className={classes.topContainer}>
                            <div className={classes.adminHeader}>
                                <img
                                    src={Jumpilot}
                                    alt="x"
                                    className={classes.logo}
                                />
                            </div>
                            <div className={classes.buttonsContainer}>
                                {birthday ? (
                                    <button
                                        className={classes.backButton}
                                        onClick={() => {
                                            // this.handleResetValues();
                                            this.setState({
                                                ...this.state,
                                                isBackModalOpen: true,
                                            });
                                        }}
                                    >
                                        {' '}
                                        Back
                                    </button>
                                ) : (
                                    <button
                                        className={classes.backButton}
                                        onClick={() => history.goBack()}
                                    >
                                        {' '}
                                        Back
                                    </button>
                                )}
                                {birthday ? (
                                    <>
                                        <button
                                            className={classes.startButton}
                                            onClick={() =>
                                                this.setState({
                                                    ...this.state,
                                                    isStopModalOpen: true,
                                                })
                                            }
                                        >
                                            Stop
                                        </button>{' '}
                                        <button
                                            onClick={() => {
                                                // this.props.stopBirthdayAPI();
                                                // clearInterval(this.startTimer);
                                                this.setState({
                                                    ...this.state,
                                                    isResetModalOpen: true,
                                                });
                                            }}
                                            className={classes.startButton}
                                        >
                                            {' '}
                                            Reset
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        onClick={() =>
                                            this.props.startBirthdayAPI({
                                                teamName: this.state.teamName,
                                                reachToGoal:
                                                    this.state.reachToGoal,
                                            })
                                        }
                                        disabled={!canStart}
                                        className={
                                            canStart
                                                ? classes.startButton
                                                : classes.disabledButton
                                        }
                                    >
                                        Start
                                    </button>
                                )}
                            </div>
                            <Modal
                                isOpen={this.state.isStopModalOpen}
                                onClose={() =>
                                    this.setState({
                                        ...this.state,
                                        isStopModalOpen: false,
                                    })
                                }
                            >
                                <div className={classes.modalWrapper}>
                                    <div>
                                        Are you sure you want to end your
                                        birthday ?
                                    </div>
                                    <div
                                        className={classes.modalButtonsWrapper}
                                    >
                                        <button
                                            className={classes.startButton}
                                            onClick={() => {
                                                this.handleStop();
                                            }}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            className={classes.startButton}
                                            onClick={() =>
                                                this.setState({
                                                    ...this.state,
                                                    isStopModalOpen: false,
                                                })
                                            }
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={this.state.isResetModalOpen}
                                onClose={() =>
                                    this.setState({
                                        ...this.state,
                                        isResetModalOpen: false,
                                    })
                                }
                            >
                                <div className={classes.modalWrapper}>
                                    <div>
                                        Are you sure you want to reset birthday?
                                    </div>
                                    <div
                                        className={classes.modalButtonsWrapper}
                                    >
                                        <button
                                            className={classes.startButton}
                                            onClick={() => {
                                                this.handleResetValues();
                                            }}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            className={classes.startButton}
                                            onClick={() =>
                                                this.setState({
                                                    ...this.state,
                                                    isResetModalOpen: false,
                                                })
                                            }
                                        >
                                            No
                                        </button>
                                    </div>
                                    <div>You cannot undo this operation.</div>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={this.state.isBackModalOpen}
                                onClose={() =>
                                    this.setState({
                                        ...this.state,
                                        isBackModalOpen: false,
                                    })
                                }
                            >
                                <div className={classes.modalWrapper}>
                                    <div>Are you sure you want to go back?</div>
                                    <div
                                        className={classes.modalButtonsWrapper}
                                    >
                                        <button
                                            className={classes.startButton}
                                            onClick={() => {
                                                this.handleBack();
                                            }}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            className={classes.startButton}
                                            onClick={() =>
                                                this.setState({
                                                    ...this.state,
                                                    isBackModalOpen: false,
                                                })
                                            }
                                        >
                                            No
                                        </button>
                                    </div>
                                    <div>You cannot undo this operation.</div>
                                </div>
                            </Modal>
                        </div>
                        <div className={classes.timerWrapper}>
                            <div>
                                <table className={classes.totalTodayGroup}>
                                    <tbody>
                                        <tr>
                                            <th className={classes.tableHeader}>
                                                Birthday set-up
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className={classes.column1}>
                                                Team's Name
                                            </td>
                                            <td className={classes.column2}>
                                                {birthday ? (
                                                    <span>
                                                        {birthday.teamName}
                                                    </span>
                                                ) : (
                                                    <input
                                                        name="teamName"
                                                        type="text"
                                                        className={
                                                            classes.inputStyle
                                                        }
                                                        onChange={this.handleInputChange(
                                                            'teamName'
                                                        )}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.column1}>
                                                Goal to reach
                                            </td>
                                            <td className={classes.column2}>
                                                {birthday ? (
                                                    <span>
                                                        {birthday.reachToGoal}
                                                    </span>
                                                ) : (
                                                    <input
                                                        type="number"
                                                        name="reachToGoal"
                                                        className={
                                                            classes.inputStyle
                                                        }
                                                        onChange={this.handleInputChange(
                                                            'reachToGoal'
                                                        )}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                {' '}
                                {birthday && (
                                    <table className={classes.totalTodayGroup}>
                                        <tbody>
                                            <tr>
                                                <th
                                                    className={
                                                        classes.tableHeader
                                                    }
                                                ></th>
                                            </tr>
                                            <tr>
                                                <td className={classes.column1}>
                                                    Time:
                                                </td>
                                                <td className={classes.column3}>
                                                    {formatSeconds(
                                                        this.state.timer
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.column1}>
                                                    Score:
                                                </td>
                                                <td className={classes.column4}>
                                                    {addSpacesInNumbers(
                                                        this.props.totalJumps
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                        <TableContainer
                            className={classes.tableContainer}
                            component={Paper}
                        >
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            className={classes.header}
                                        >
                                            Avatar
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={classes.header}
                                        >
                                            Result
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={classes.header}
                                        >
                                            Users
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={classes.header}
                                        >
                                            Print results
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={classes.header}
                                        >
                                            Print diploma
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedPlayers.map((player) => {
                                        return (
                                            <AdminPanelTableItem
                                                key={player.id}
                                                id={player.id}
                                                jumps={player.jumps}
                                                score={player.score}
                                                users={player.users}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </div>
            )
        );
    }
}

let mapStateToProps = (state) => {
    return {
        allJumps: state.adminPanel.allJumps,
        allUsers: state.adminPanel.allUsers,
        allResults: state.adminPanel.results,
        totalJumps: state.sseReducer.totalJumps,
        birthday: state.sseReducer.birthday,
        currentGame: state.sseReducer.currentGame,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllJumpsandUsers: () => {
            dispatch(getAllJumpsAndUsers());
        },
        getResults: () => {
            dispatch(getResults());
        },
        startBirthdayAPI: (body) => {
            dispatch(startBirthdayAPI(body));
        },
        stopBirthdayAPI: () => {
            dispatch(stopBirthdayAPI());
        },
        resetBirthdayAPI: () => {
            dispatch(resetBirthdayAPI());
        },
        stopBirthdayAndSaveDataAPI: () => {
            dispatch(stopBirthdayAndSaveDataAPI());
        },
        getBirthdayAPI: () => {
            dispatch(getBirthdayAPI());
        },
    };
};

const styledComponent = withStyles(styles)(BirthdayPanel);
const ShowTheLocationWithRouter = withRouter(BirthdayPanel);

export default connect(mapStateToProps, mapDispatchToProps)(
    styledComponent,
    ShowTheLocationWithRouter
);
