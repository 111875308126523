export const GET_ALL_JUMPS_AND_USERS = "GET ALL JUMPS AND USERS";
export const SET_ALL_JUMPS_AND_USERS = "SET ALL JUMPS AND USERS";
export const SET_SELECTED_BAND_ID = "SET SELECTED BAND ID";
export const GET_RESULTS = "GET RESULTS";
export const SET_RESULTS = "SET RESULTS";
export const GET_QR_CODE = "GET_QR_CODE";
export const SET_QR_CODE = "SET_QR_CODE";
export const RESET_QR_CODE = "RESET_QR_CODE";
export const START_BIRTHDAY = "START_BIRTHDAY";
export const STOP_BIRTHDAY = "STOP_BIRTHDAY";

export const getAllJumpsAndUsers = () => {
  return {
    type: GET_ALL_JUMPS_AND_USERS,
  };
};

export const startBirthday = (body) => {
  return {
    type: START_BIRTHDAY,
    payload: body,
  };
};
export const stopBirthday = () => {
  return {
    type: STOP_BIRTHDAY,
  };
};

export const setAllJumpsAndUsers = (body) => {
  return {
    type: SET_ALL_JUMPS_AND_USERS,
    payload: body,
  };
};

export const setSelectedBand = (id) => {
  return {
    type: SET_SELECTED_BAND_ID,
    payload: id,
  };
};

export const getResults = () => {
  return {
    type: GET_RESULTS,
  };
};

export const setResults = (results) => {
  return {
    type: SET_RESULTS,
    payload: results,
  };
};

export const getQRCode = (body) => {
  return {
    type: GET_QR_CODE,
    payload: body,
  };
};

export const setQRCode = (body) => {
  return {
    type: SET_QR_CODE,
    payload: body,
  };
};

export const resetQRCode = () => {
  return {
    type: RESET_QR_CODE,
  };
};
