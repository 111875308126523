import {
    GET_BIRTHDAY,
    GET_GAMES,
    GET_JUMPS,
    RESET_ANNOUNCEMENT,
    SET_CHALLENGE,
    SET_PLAYERS_FOR_CHALLENGE,
    START_BIRTHDAY,
    STOP_BIRTHDAY,
    STOP_BIRTHDAY_SAVE_DATA,
} from '../actions/sse.action';
import { SET_BEST_OF_THE_DAY } from '../actions/ranking.action';

const filterLeaderboardArr = (newPlayer, players, bestOfTheDay) => {
    let updatedPlayers = [...players.filter((player) => player.sessionId !== newPlayer.sessionId)];

    if (!isNewPlayerInactive(newPlayer, players)) {
        updatedPlayers.push(newPlayer);
    }
    if (bestOfTheDay && bestOfTheDay.sessionId === newPlayer.sessionId && bestOfTheDay.value !== newPlayer.value) {
        bestOfTheDay.gameOver = newPlayer.gameOver;
        updatedPlayers.push(bestOfTheDay);
    }
    let finalList = updatedPlayers;

    if (players && bestOfTheDay) {
        finalList = updatedPlayers.filter((player) => player && bestOfTheDay && !(player.gameOver && player.sessionId !== bestOfTheDay.sessionId))
    }

    if (!newPlayer) return players;
    return finalList.filter((player) => player.value !== 0);
};

const isNewPlayerInactive = (newPlayer, players) => {
    const oldPlayer = players.filter((player) => player.sessionId === newPlayer.sessionId)
    .filter(player => player.value !== 0);
    return oldPlayer[0] && oldPlayer[0].value !== 0 && newPlayer.value === 0;
};

const filterChallengeArr = (newPlayer, players) => {
    const playersArr = [...players];
    if (playersArr.length > 0) {
        const keys = [];
        playersArr.map((p) => {
            keys.push(p.id);
        });
        if (keys.every((k) => k != newPlayer.id)) {
            playersArr.push(newPlayer);
        } else {
            playersArr.map((p) => {
                if (p.id === newPlayer.id) {
                    p.jumps = newPlayer.jumps;
                }
            });
        }
    } else {
        playersArr.push(newPlayer);
    }
    return playersArr;
};

const convert = (player) => {
    const newPlayer = {
        id: player.id,
        jumps: player.jumps,
        score: 0,
        winner: false,
    };
    switch (newPlayer.jumps) {
        case 150:
            return { id: newPlayer.id, jumps: 30, score: 150, winner: true };
        case 125:
            return { id: newPlayer.id, jumps: 30, score: 125, winner: true };
        case 100:
            return { id: newPlayer.id, jumps: 30, score: 100, winner: true };
        case 50:
            return { id: newPlayer.id, jumps: 30, score: 50, winner: true };
        default:
            const jumps = Math.floor(newPlayer.jumps / 1000);
            return { id: newPlayer.id, jumps: jumps, score: 0, winner: false };
    }
};

const filterAirTimeArr = (newJumper, players) => {
    const playersArr = [...players];
    const newPlayer = convert(newJumper);
    if (playersArr.length > 0) {
        const keys = [];
        playersArr.map((p) => {
            keys.push(p.id);
        });
        if (keys.every((k) => k != newPlayer.id)) {
            playersArr.push(newPlayer);
        } else {
            playersArr.map((p) => {
                if (p.id === newPlayer.id) {
                    p.jumps = newPlayer.jumps;
                    p.score = newPlayer.score;
                    p.winner = newPlayer.winner;
                }
            });
        }
    } else {
        playersArr.push(newPlayer);
    }
    return playersArr;
};

const initialState = {
    players: [],
    currentGame: '',
    announcementGamePayload: '',
    challengePlayers: [],
    birthday: null,
    totalJumps: 0,
    bestOfTheDay: null
};

const sseReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHALLENGE:
            return {
                ...state,
                currentGame: action.payload,
            };
        case GET_BIRTHDAY:
            return {
                ...state,
                birthday: action.payload.gameParams,
            };
        case START_BIRTHDAY:
            return {
                ...state,
                birthday: action.payload,
            };
        case STOP_BIRTHDAY:
            return {
                ...state,
                birthday: null,
                totalJumps: 0,
            };
        case STOP_BIRTHDAY_SAVE_DATA:
            return {
                ...state,
            };
        case GET_JUMPS:
            return {
                ...state,
                players: filterLeaderboardArr(action.body, state.players, state.bestOfTheDay),
            };
        case SET_BEST_OF_THE_DAY:
            return {
                ...state,
                bestOfTheDay: action.payload,
            };
        case SET_PLAYERS_FOR_CHALLENGE:
            return {
                ...state,
                challengePlayers: action.payload,
            };
        case RESET_ANNOUNCEMENT:
            return {
                ...state,
                announcementGamePayload: '',
                currentGame: 'simple-game',
                challengePlayers: [],
            };
        case GET_GAMES:
            if (action.body.payload) {
                if (action.body.type === 'currentState') {
                    return {
                        ...state,
                        currentGame: action.body.payload.gameAnnouncement,
                    };
                    // } else if (action.body.type === 'announcement') {
                    //     console.log(action.body.payload);
                    //     return {
                    //         ...state,
                    //         announcementGamePayload: action.body.payload,
                    //     };
                    // } else if (action.body.payload.gameName === 'flash-test') {
                    //     const newChallengePlayer = action.body.payload.resultModel;
                    //     return {
                    //         ...state,
                    //         challengePlayers: filterChallengeArr(
                    //             newChallengePlayer,
                    //             state.challengePlayers
                    //         ),
                    //     };
                    // } else if (action.body.payload.gameName === 'happy-time') {
                    //     const newChallengePlayer = action.body.payload.resultModel;
                    //     return {
                    //         ...state,
                    //         challengePlayers: filterChallengeArr(
                    //             newChallengePlayer,
                    //             state.challengePlayers
                    //         ),
                    //     };
                    // } else if (action.body.payload.gameName === 'airtime') {
                    //     const newChallengePlayer = action.body.payload.resultModel;
                    //     return {
                    //         ...state,
                    //         challengePlayers: filterAirTimeArr(
                    //             newChallengePlayer,
                    //             state.challengePlayers
                    //         ),
                    //     };
                } else if (action.body.type === 'results') {
                    const totalAmountOfBirthdayPoints =
                        parseInt(action.body.payload.total) ?? 0;
                    return {
                        ...state,
                        totalJumps: totalAmountOfBirthdayPoints,
                    };
                }
            }
        default:
            return state;
    }
};
export default sseReducer;
