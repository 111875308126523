export const GET_BEST_OF_THE_DAY = 'GET_BEST_OF_THE_DAY'
export const SET_BEST_OF_THE_DAY = 'SET_BEST_OF_THE_DAY'

export const getBestOfTheDay = () => {
  return {
    type: GET_BEST_OF_THE_DAY
  }
}

export const setBestOfTheDay = (body) => {
  return {
    type: SET_BEST_OF_THE_DAY,
    payload: body
  }
}