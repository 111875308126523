import styled from "styled-components";

export const ModalStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 200px;
  transform: translate(-50%, -50%);
  background: #fff;
  font-family: SairaCondensed-Black;
  padding: 40px;
  z-index: 100;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 28px;
  color: #1E1138;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

export const ModalWrapper = styled.div``;

