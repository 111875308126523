import React, {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setSelectedBand} from '../../store/actions/adminPanel.action'
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import {useStyles} from './AdminPanelTableItemStyles'
import { useHistory } from "react-router-dom";
import elements from '../TagHeroez/HeroPoints/elements';

export default function AdminPanelTableItem({id, jumps, score, users}) {
    const classes = useStyles();

    let history = useHistory();

    const dispatch = useDispatch();

    const icons = useSelector(state => state.adminPanel.icons)

    const handlePrintDiploma = useCallback(() => {
      dispatch(setSelectedBand(id));
      window.localStorage.setItem('id', id);
      window.open('./diploma', "_blank");
    }, [dispatch, history, id])

    const handlePrintResults = useCallback(() => {
      dispatch(setSelectedBand(id));
      window.localStorage.setItem('id', id);
      window.open('./diplomawithouttemplate', "_blank")
    }, [dispatch, history, id])

    return (
        <TableRow>
          <TableCell className={classes.tableCell} align="center">
            <img className={classes.icon} src={elements[id]?.icon} alt="icon"/>
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            <div>
              {jumps + score}
            </div>
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            <div>
              {users}
            </div>
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            <Button 
              className={classes.printButton}
              onClick={handlePrintResults}
            >
              Print
            </Button>
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            <Button 
              className={classes.printButton}
              onClick={handlePrintDiploma}
            >
              Print
            </Button>
          </TableCell>
        </TableRow>
    )
}
