export const GET_JUMPS = 'GET_JUMPS';
export const OPEN_SSE_MAIN = 'OPEN_SSE_MAIN';
export const OPEN_SSE_GAMES = 'OPEN_SSE_GAMES';
export const GET_GAMES = 'GET_GAMES';
export const SET_PLAYERS_FOR_CHALLENGE = 'SET PLAYERS FOR CHALLENGE';
export const RESET_ANNOUNCEMENT = 'RESET ANNOUNCEMENT';
export const SET_CHALLENGE = 'SET_CHALLENGE';
export const GET_BIRTHDAY = 'GET_BIRTHDAY';
export const START_BIRTHDAY = 'START_BIRTHDAY';
export const STOP_BIRTHDAY = 'STOP_BIRTHDAY';
export const GET_CURRENT_GAME = 'CURRENT_GAME';
export const STOP_BIRTHDAY_SAVE_DATA = 'STOP_BIRTHDAY_SAVE_DATA';

export const openSSEMain = () => {
    return { type: OPEN_SSE_MAIN };
};

export const getJumps = (body) => {
    return { type: GET_JUMPS, body };
};

export const getBirthday = (body) => {
    return { type: GET_BIRTHDAY, payload: body };
};

export const startBirthday = (body) => {
    return {
        type: START_BIRTHDAY,
        payload: body,
    };
};
export const stopBirthday = () => {
    return {
        type: STOP_BIRTHDAY,
    };
};

export const stopBirthdaySaveData = () => {
    return {
        type: STOP_BIRTHDAY_SAVE_DATA,
    };
};

export const openSSEGames = () => {
    return { type: OPEN_SSE_GAMES };
};

export const getGames = (body) => {
    return { type: GET_GAMES, body };
};

export const setPlayersForChallenge = (players) => {
    return {
        type: SET_PLAYERS_FOR_CHALLENGE,
        payload: players.map((player) => ({
            id: Number(player.key),
            jumps: 0,
            score: 0,
            winner: false,
        })),
    };
};

export const resetAnnouncement = () => {
    return {
        type: RESET_ANNOUNCEMENT,
    };
};

export const setChallenge = (body) => {
    return {
        type: SET_CHALLENGE,
        payload: body,
    };
};
