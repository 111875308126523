import {SET_BEST_OF_THE_DAY} from "../actions/ranking.action";

const initialState = {
  bestOfTheDay: null
}

export const rankingReducer = (state = initialState, action) => {
  switch(action.type){
    case SET_BEST_OF_THE_DAY:
      return {
        ...state,
        bestOfTheDay: {key: action.payload.key, value: action.payload.value, sessionId: action.payload.sessionId}
      }
    default:
      return state
  }
}