import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import { useHistory, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import classes from './HeroPoints.module.css';
import elements from './elements.js';
import transition from '../../../assets/default/gif/ninjatag_PRZEJSCIE.gif';
import head_transition from '../../../assets/default/gif/ninjatag_GLOWKA.gif';
import scoreIcon from '../../../assets/default/images/ranking/ikonka.svg';

const HeroPoints = (props) => {
    const history = useHistory();
    const [activeTransition, setActiveTransition] = useState('hidden');
    const [headTransition, setHeadTransition] = useState('hidden');
    const [characterSwitch, setCharacterSwitch] = useState(false);
    const [playerScore, setPlayerScore] = useState(0);
    const [perc, setPerc] = useState(0);

    const allPlayers = useSelector((state) => state.sseReducer.players);

    const userFromBand = props.userFromBand;
    const heroID = userFromBand.id ?? 11;
    // const playerScore = userFromBand.jumps ?? 0;
    // console.log('Players', allPlayers);
    // console.log('Player Score: ', playerScore);
    const chosen = elements[heroID];

    const score_containter_size = playerScore > 999 ? '30%' : '25%';

    useEffect(() => {
        setPlayerScore(
            allPlayers?.find((player) => player.key === heroID.toString())
                ?.value ?? 0
        );

        setTimeout(() => {
            setPerc(75);
        }, 200);
        setTimeout(() => {
            setHeadTransition('visible');
        }, 1200);
        setTimeout(() => {
            setCharacterSwitch(true);
        }, 1700);
        setTimeout(() => {
            setHeadTransition('hidden');
        }, 2400);
        setTimeout(() => {
            setActiveTransition('visible');
        }, 3500);
        setTimeout(() => {
            setActiveTransition('hidden');
            history.push('/ninjatag/final-transition');
        }, 4450);
    }, []);

    return (
        <div className={classes.container}>
            {chosen.character && (
                <img
                    src={head_transition}
                    alt="Head transition gif"
                    className={classes.transition}
                    style={{ visibility: headTransition }}
                />
            )}
            <img
                src={transition}
                alt="Transition gif"
                className={classes.transition}
                style={{ visibility: activeTransition }}
            />
            <div className={classes.hero_container}>
                <img
                    src={chosen.charName}
                    alt="Name 1"
                    className={classes.hero_name}
                />
                {chosen.character ? (
                    !characterSwitch ? (
                        <img
                            src={chosen.character}
                            alt="Char 1"
                            className={classes.hero_png}
                        />
                    ) : (
                        <img
                            src={chosen.icon}
                            alt="Hero Icon"
                            className={classes.icon_big}
                        />
                    )
                ) : (
                    <img
                        src={chosen.icon}
                        alt="Hero Icon"
                        className={classes.icon_big}
                    />
                )}
            </div>

            <div className={classes.score_container}>
                <div className={classes.icon_with_progress_bar}>
                    <img
                        src={chosen.icon}
                        alt="Icon 1"
                        className={classes.icon}
                    />
                    <div className={classes.score_bar_wrapper}>
                        <div
                            className={classes.bar}
                            style={{ width: `${perc}%` }}
                        />
                    </div>
                </div>
                <div
                    className={classes.score}
                    style={{ width: `${score_containter_size}` }}
                >
                    <CountUp
                        end={playerScore}
                        duration={1.8}
                        useEasing={true}
                        delay={0.5}
                    />
                    <img
                        src={scoreIcon}
                        alt="Icon 1"
                        className={classes.scoreIcon}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userFromBand: state.scanBand.userFromBand,
    };
};

export default withRouter(connect(mapStateToProps)(HeroPoints));
