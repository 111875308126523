import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import styles from './AdminPanelStyles';
import { withStyles } from '@material-ui/styles';
import AdminPanelTableItem from './AdminPanelTableItem';
import { addAlert } from '../../store/actions/stickyAlert.action';
import {
    getAllJumpsAndUsers,
    getResults,
} from '../../store/actions/adminPanel.action';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { withRouter } from 'react-router-dom';
import Jumpilot from '../../assets/default/images/diploma/JUMPILOT_LOGO.png';

const ZENDESK_KEY = '380e4da7-1b2d-4b32-9297-04c722cd1d8b';

const setting = {
    color: {
        theme: '#6414cc',
        launcherText: '#fff',
    },
    title: 'Chat with us!',
    contactForm: {
        fields: [
            {
                id: 'description',
                prefill: { '*': 'My pre-filled description' },
            },
        ],
    },
};

class AdminPanel extends React.Component {
    getAllJumpsandUsersTimeOut;
    getResultsTimeOut;

    componentDidMount() {
        ZendeskAPI('webWidget', 'toggle');

        this.props.getAllJumpsandUsers();
        this.props.getResults();
        this.getAllJumpsandUsersTimeOut = setInterval(() => {
            this.props.getAllJumpsandUsers();
        }, 60000);
        this.getResultsTimeOut = setInterval(() => {
            this.props.getResults();
        }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.getAllJumpsandUsersTimeOut);
        clearInterval(this.getResultsTimeOut);
    }

    render() {
        const { history } = this.props;
        ZendeskAPI('webWidget', 'setLocale', 'en');
        const { classes, allResults } = this.props;
        console.log('allResults', allResults);
        const sortedPlayers = allResults.sort(
            (a, b) => b.jumps + b.score - (a.jumps + a.score)
        );

        // if(this.props.currentGame.gameId === "birthday-game") {
        //   history.push("/admin/birthday");
        // }

        return (
            sortedPlayers && (
                <div className={classes.wrapper}>
                    <Zendesk zendeskKey={ZENDESK_KEY} {...setting} />
                    <Box component={Paper} className={classes.box}>
                        <div className={classes.topContainer}>
                            <div className={classes.adminHeader}>
                                <img
                                    src={Jumpilot}
                                    alt="x"
                                    className={classes.logo}
                                />
                            </div>
                            <button
                                className={classes.birthdayButton}
                                onClick={() => history.push('/admin/birthday')}
                            >
                                Start Birthday
                            </button>
                        </div>
                        <table className={classes.totalTodayGroup}>
                            <tbody>
                                <tr>
                                    <th className={classes.tableHeader}>
                                        Total today
                                    </th>
                                </tr>
                                <tr>
                                    <td className={classes.column1}>Jumps</td>
                                    <td className={classes.column2}>
                                        {this.props.allJumps}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={classes.column1}>Users</td>
                                    <td className={classes.column2}>
                                        {this.props.allUsers}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <TableContainer
                            className={classes.tableContainer}
                            component={Paper}
                        >
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            className={classes.header}
                                        >
                                            Avatar
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={classes.header}
                                        >
                                            Result
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={classes.header}
                                        >
                                            Users
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={classes.header}
                                        >
                                            Print results
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={classes.header}
                                        >
                                            Print diploma
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedPlayers.map((player) => {
                                        return (
                                            <AdminPanelTableItem
                                                key={player.id}
                                                id={player.id}
                                                jumps={player.jumps}
                                                score={player.score}
                                                users={player.users}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </div>
            )
        );
    }
}

let mapStateToProps = (state) => {
    return {
        allJumps: state.adminPanel.allJumps,
        allUsers: state.adminPanel.allUsers,
        allResults: state.adminPanel.results,
        currentGame: state.sseReducer.currentGame,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllJumpsandUsers: () => {
            dispatch(getAllJumpsAndUsers());
        },
        getResults: () => {
            dispatch(getResults());
        },
        addAlert: (error) => {
            dispatch(addAlert(error));
        },
    };
};

const styledComponent = withStyles(styles)(AdminPanel);
const showTheLocationWithRouter = withRouter(AdminPanel);

export default connect(mapStateToProps, mapDispatchToProps)(
    styledComponent,
    showTheLocationWithRouter
);
