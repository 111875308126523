import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import classes from './FinalTransition.module.css';
// import countdown_1 from '../../../assets/default/images/tag-heroez/countdown_1.png';
// import countdown_2 from '../../../assets/default/images/tag-heroez/countdown_2.png';
// import countdown_3 from '../../../assets/default/images/tag-heroez/countdown_3.png';

import transition from '../../../assets/default/gif/ninjatag_PRZEJSCIE.gif';
import graj_blysk from '../../../assets/default/gif/ninjatag_GRAJ_BLYSK.gif';
import { setBandScanned } from '../../../store/actions/scanBand.action.js';

const FinalTransition = (props) => {
    const history = useHistory();
    const [activeTransition, setActiveTransition] = useState('hidden');
    const [grajActive, setGrajActive] = useState('hidden');
    const [blyskActive, setBlyskActive] = useState('hidden');

    useEffect(() => {
        // setTimeout(() => {
        //     setGrajActive('visible');
        // }, 4000);
        // setTimeout(() => {
        //     setBlyskActive('visible');
        // }, 4300);
        // setTimeout(() => {
        //     setBlyskActive('hidden');
        //     setActiveTransition('visible');
        // }, 5000);
        // setTimeout(() => {
        //     props.setBandScanned(false);
        //     history.push('/ninjatag/scan');
        // }, 5950);
        setTimeout(() => {
            setGrajActive('visible');
        }, 100);
        setTimeout(() => {
            setBlyskActive('visible');
        }, 400);
        setTimeout(() => {
            setBlyskActive('hidden');
            setActiveTransition('visible');
        }, 1100);
        setTimeout(() => {
            props.setBandScanned(false);
            history.push('/ninjatag/scan');
        }, 2050);
    }, []);

    return (
        <div className={classes.container}>
            <img
                src={transition}
                alt="Transition gif"
                className={classes.transition}
                style={{ visibility: activeTransition }}
            />
            <img
                src={graj_blysk}
                alt="Graj blysk"
                className={classes.transition}
                style={{ visibility: blyskActive }}
            />
            <img
                src={'/locale/images/tag-heroes/countdown_PLAY.png'}
                alt="Graj"
                className={classes.graj}
                style={{ visibility: grajActive }}
            />
            {/*
            <img
                src={countdown_3}
                alt="Countdown 3"
                className={classes.count_2}
            />
            <img
                src={countdown_2}
                alt="Countdown 2"
                className={classes.count_2}
                style={{ animationDelay: '1.3s' }}
            />
            <img
                src={countdown_1}
                alt="Countdown 1"
                className={classes.count_1}
                style={{ animationDelay: '2.5s' }}
            />
            */}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        bandScanned: state.scanBand.bandScanned,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setBandScanned: (scanned) => {
            dispatch(setBandScanned(scanned));
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FinalTransition)
);
