import moment from 'moment';

const setCurrentGame = (currentGame, url, history, playersForChallenge, cb) => {
    const {gameId, duration, startTime} = currentGame;
    if(gameId && url !== gameId){
        playersForChallenge && cb(playersForChallenge);
        history.push(`/${gameId}`);
    } else {
        const start = moment(startTime);
        const now = moment();
        const diff = now.diff(start);
        const countDown = Math.round((duration * 1000 - diff) / 1000);
        return countDown;
    }
}

export default setCurrentGame