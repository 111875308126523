import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import DiplomaSampleWithoutTemplate from './DiplomaSampleWithoutTemplate'
import {getQRCode, getResults, resetQRCode} from '../../../store/actions/adminPanel.action'
import { Redirect } from 'react-router-dom';

export default function SingleDiploma() {

    const dispatch = useDispatch()

    const selectedBandID = localStorage.getItem('id')

    useEffect(() => {
        dispatch(resetQRCode())
    },[dispatch])

    useEffect(() => {
        dispatch(getResults())
    },[dispatch])

    useEffect(() => {
        selectedBandID && dispatch(getQRCode({id: selectedBandID}))
    },[dispatch])

    const selectedBandQRCode = useSelector(state => state.adminPanel.selectedBandQRCode)

    const results  = useSelector(state => state.adminPanel.results)

    const [selectedResult, setSelectedResult] = useState(null);

    useEffect(() => {
        results.length > 0 && setSelectedResult(results.find(result => result.id == selectedBandID))
    },[results]);


    useEffect(() => {
        selectedResult && setTimeout(()=> {window.print()}, 2000)
    }, [selectedResult])

    return  !selectedBandID  ? <Redirect to="/admin" /> :
      <>
          {  selectedResult && selectedBandQRCode ?
            <DiplomaSampleWithoutTemplate
              selectedBandQRCode={selectedBandQRCode}
              id={selectedResult.id}
              flightTime={Math.round(selectedResult.jumpFlight / 1000)}
              jumps={selectedResult.jumps}
              score={selectedResult.score}
            />
            : null
          }
      </>
    }
