/* eslint-disable import/no-anonymous-default-export */
import Background from '../../assets/default/images/ranking/background.png';

export default {
    wrapper: {
        margin: 0,
        paddingTop: '100px',
        paddingBottom: '100px',
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    box: {
        margin: 'auto',
        borderRadius: '30px',
        overflow: 'hidden',
        maxWidth: '1000px',
        width: '90%',
    },
    logo: {
        width: '250px',
        height: '120px',
    },
    table: {
        minWidth: 650,
    },
    adminHeader: {
        color: '#1e1138',
        fontFamily: 'SairaCondensed-Black',
        width: '50%',
        fontSize: '41px',
        lineHeight: '4.756',
        textAlign: 'left',
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '3%',
        paddingTop: '3%',
        paddingLeft: '3%',
    },
    birthdayButton: {
        textDecoration: `none`,
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        background: '#FC119A',
        fontSize: '24px',
        color: '#fff',
        borderRadius: '10px',
        padding: '10px',
        width: 'min-content',
        whiteSpace: 'nowrap',
        fontFamily: 'Saira-SemiBold',
    },
    totalTodayGroup: {
        paddingLeft: '3%',
        fontFamily: 'Saira-SemiBold',
        color: '#1e1138',
    },
    tableContainer: {
        marginTop: '5%',
    },
    tableHeader: {
        padding: '0 0 20px 0',
        fontSize: '33px',
        lineHeight: '1.364',
        fontWeight: 'bold',
    },
    column1: {
        fontFamily: 'Saira-Regular',
        fontSize: '28px',
        fontWeight: 'bold',
    },
    column2: {
        fontSize: '28px',
        color: '#9966ff',
        fontWeight: 'bold',
    },
    resetButton: {
        fontFamily: 'Saira-Regular',
        background: '#ec0f9e',
        color: 'white',
        fontSize: '23px',
        width: '60%',
        padding: '7px 10px 2px 10px',
        border: '2px solid #ec0f9e',
        '&:hover': {
            color: '#ec0f9e',
        },
    },
    printButton: {
        fontFamily: 'Saira-Regular',
        background: '#00ebc6',
        padding: '7px 10px 2px 10px',
        width: '60%',
        color: 'white',
        fontSize: '23px',
        border: '2px solid #00ebc6',
        '&:hover': {
            color: '#00ebc6',
        },
    },
    diplomaLink: {
        fontFamily: 'Saira-Regular',
        textDecoration: 'none',
        color: 'white',
        '&:hover': {
            color: '#00ebc6',
        },
    },
    header: {
        color: '#1e1138',
        fontFamily: 'Saira-SemiBold',
        fontWeight: 'bold',
        fontSize: '28px',
        background: '#ddd',
    },
};
