import React, { useEffect, useState } from 'react';
import classes from './RankingFooter.module.css';
import Logo from '../../../../assets/default/images/ranking/JUMPILOTLOGO.png';
import lightningIcon from '../../../../assets/default/images/ranking/ikonka.svg';

const RankingFooter = ({ isBirthdayGame, scores, numOfPages, pageActive }) => {
    const totalPoints = scores.reduce((a, b) => a + b, 0);

    const dots = Array.from(Array(numOfPages).keys());

    return (
        <div className={classes.logoContainer}>
            <img className={classes.logo} src={Logo} alt="GoJump Logo" />
            {isBirthdayGame ? (
                <div className={classes.birthdayContainer}>
                    <div className={classes.birthdayInsideContainer}>
                        <div className={classes.totalText}>
                            {'total'.toUpperCase()}
                        </div>
                        <div className={classes.totalPoints}>{totalPoints}</div>
                        <div className={classes.iconContainer}>
                            <img
                                className={classes.icon}
                                src={lightningIcon}
                                alt="lightningIcon"
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classes.paginationContainer}>
                    <div className={classes.paginationDots}>
                        {dots.map((dot) => {
                            return (
                                <span
                                    key={dot}
                                    className={
                                        dot + 1 === pageActive
                                            ? classes.dotActive
                                            : classes.dot
                                    }
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RankingFooter;
