import React, { memo, useEffect, useMemo, useState } from 'react';
import classes from './Ranking.module.css';
import { useSelector } from 'react-redux';
import { dynamicSort } from '../../../common/dynamicSort';
import { NUM_OF_PLAYERS_PER_PAGE, RANKING_PAGINATION_INTERVAL_IN_MS } from '../common/constants';

import RankingHeader from './Header/RankingHeader';
import RankingFooter from './Footer/RankingFooter';
import Player from './Player/Player';

const Ranking = ({ height, showLeaderboard, showTransition }) => {
    const heroOfTheDay = useSelector((state) => state.ranking.bestOfTheDay);
    const playersWithoutBestOfTheDay = useSelector(
        (state) => state.sseReducer.players
    );
    const birthdayInfo = useSelector((state) => state.sseReducer.birthday);
    const currentGame = useSelector((state) => state.sseReducer.currentGame);

    const [teamName, setTeamName] = useState('Jasia');

    useEffect(() => {
        if (birthdayInfo?.teamName) {
            setTeamName(birthdayInfo.teamName);
        }
    }, [birthdayInfo]);

    const heroOfTheDayJumps = heroOfTheDay && heroOfTheDay.value;
    const isHeroTheBest =
        heroOfTheDay &&
        playersWithoutBestOfTheDay.every(
            (player) => player.sessionId !== heroOfTheDay.sessionId
        );

    const players = useMemo(
        () =>
            isHeroTheBest
                ? [...playersWithoutBestOfTheDay, heroOfTheDay].sort(
                      dynamicSort('key')
                  )
                : [...playersWithoutBestOfTheDay].sort(dynamicSort('key')),
        [isHeroTheBest, playersWithoutBestOfTheDay, heroOfTheDay]
    );

    const sortedPlayers = useMemo(
        () => [...players].sort(dynamicSort('value')),
        [players]
    );

    // console.log(players);

    const [numOfPages, setNumOfPages] = useState(1);

    useMemo(() => {
        const arr = [];
        players.forEach((pl) => {
            for (let i = 0; i < sortedPlayers.length; i++) {
                if (
                    pl.value === sortedPlayers[i].value &&
                    !arr.includes(sortedPlayers[i].key)
                ) {
                    pl.index = i;
                    arr.push(sortedPlayers[i].key);
                    break;
                }
            }
        });
        setNumOfPages(Math.ceil(players.length / NUM_OF_PLAYERS_PER_PAGE));
    }, [players]);

    const scores = useMemo(() => players.map((item) => item.value), [players]);
    const maxId = heroOfTheDay && heroOfTheDay.key;
    const maxValue = heroOfTheDay && heroOfTheDay.value;

    // const { groupNames, groupFontSize } = groupNameFontSize(team);

    const [isBirthday, setIsBirthday] = useState(false);

    useEffect(() => {
        currentGame.gameId === 'birthday-game'
            ? setIsBirthday(true)
            : setIsBirthday(false);
    }, [currentGame]);

    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        const paginationTimeout = setTimeout(() => {
            if (currentPage < numOfPages) {
                setCurrentPage((prev) => prev + 1);
            } else {
                setCurrentPage(1);
            }
        }, RANKING_PAGINATION_INTERVAL_IN_MS);
        return () => clearTimeout(paginationTimeout);
    }, [numOfPages, currentPage]);

    return (
        <>
            <div
                className={classes.leaderboardWrapper}
                style={{ visibility: showLeaderboard }}
            >
                {/* <img
                    src={transition}
                    alt="transition"
                    className={classes.transition}
                    style={{ visibility: showTransition }}
                /> */}
                <div className={classes.containerWrapper}>
                    <RankingHeader
                        headerTitle={
                            isBirthday
                                ? `Urodziny ${teamName}`
                                : 'Hero of the day'
                        }
                    />
                    <div className={classes.resultsBackground}>
                        {players.length > 0 && (
                            <div className={classes.results}>
                                {players
                                    // .filter(
                                    //     (x) =>
                                    //         x.index === 0 || (
                                    //         x.index >= lowerRankingBound &&
                                    //         x.index < upperRankingBound)
                                    // )
                                    .map((item, idx) => {
                                        return (
                                            <Player
                                                key={idx}
                                                idx={item.index}
                                                maxId={maxId}
                                                maxValue={maxValue}
                                                item={item}
                                                currentPage={currentPage}
                                            />
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                    <RankingFooter
                        isBirthdayGame={isBirthday}
                        scores={scores}
                        numOfPages={numOfPages}
                        pageActive={currentPage}
                    />
                </div>
            </div>
        </>
    );
};

export default memo(Ranking);
