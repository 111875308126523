import {
    getJumps,
    OPEN_SSE_MAIN,
    OPEN_SSE_GAMES,
    getGames,
} from '../store/actions/sse.action';

import {
    OPEN_SSE_SCAN_BAND,
    getBandID,
    setBandScanned,
} from '../store/actions/scanBand.action.js';
import { baseUrl, rawBackendUrl } from '../api/config';
import {
    setBestOfTheDay,
} from '../store/actions/ranking.action';

const reconnectInterval = 5000; // 5 seconds

const sseMiddleware = () => {
    let eventSource = null;
    let eventSourceBandScan = null;

    const onMessage = (store) => (event) => {
        const payload = JSON.parse(event.data);
        if (payload.hero) {
            store.dispatch(setBestOfTheDay(payload));
        }
        store.dispatch(getJumps(payload));
    };

    const onMessageGames = (store) => (event) => {
        const payload = JSON.parse(event.data);
        store.dispatch(getGames(payload));
    };

    const onMessageScan = (store) => (event) => {
        const payload = JSON.parse(event.data);
        if (payload) {
            store.dispatch(getBandID(payload));
            store.dispatch(setBandScanned(true));
        }
    };

    const onError = (store, type) => (event) => {
        // TODO: send info to backend or kibana
        console.log('EVENTSTREAM ERROR', event);
        const today = new Date();
        const date =
            today.getDate() +
            '-' +
            (today.getMonth() + 1) +
            '-' +
            today.getFullYear();
        const time =
            today.getHours() +
            ':' +
            today.getMinutes() +
            ':' +
            today.getSeconds();
        window.localStorage.setItem(date + '-' + time, event);

        if (type === OPEN_SSE_MAIN) {
            eventSource.close();
            setTimeout(() => {
                store.dispatch({ type: OPEN_SSE_MAIN });
            }, reconnectInterval);
        } else if (type === OPEN_SSE_SCAN_BAND) {
            eventSourceBandScan.close();
            setTimeout(() => {
                store.dispatch({ type: OPEN_SSE_SCAN_BAND });
            }, reconnectInterval);
        }
    };

    return (store) => (next) => (action) => {
        switch (action.type) {
            case OPEN_SSE_MAIN:
                if (eventSource !== null) {
                    eventSource.close();
                }
                console.log(baseUrl);
                eventSource = new EventSource(baseUrl + '/jumps-stream');
                eventSource.onmessage = onMessage(store);
                eventSource.onerror = onError(store, OPEN_SSE_MAIN);
                break;

            case OPEN_SSE_SCAN_BAND:
                if (eventSourceBandScan !== null) {
                    eventSourceBandScan.close();
                }
                eventSourceBandScan = new EventSource(
                    'http://localhost:3005/stream'
                );
                eventSourceBandScan.onmessage = onMessageScan(store);
                eventSourceBandScan.onerror = onError(store, OPEN_SSE_SCAN_BAND);
                break;
            default:
                return next(action);
        }
    };
};

export default sseMiddleware();
