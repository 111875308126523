const countOccurences = (scores, num) => {
    let result = 0;
    // Maybe map instead of forEach?
    scores.forEach((score) => {
        if (score === num) {
            result++;
        }
    });
    return result;
};

export default countOccurences;
